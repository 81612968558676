.AppsButton {
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.025);
    border-radius: 10px;
    margin-left: 1rem;
    position: relative;
}
.AppsButton:hover {
    cursor: pointer;
    background-color: var(--primary-background-color);
}
.AppsButton:hover .Icon div{
    background-color: var(--primary-color);
}
.AppsButton .Icon div{
    transition: all 0.3s ease;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 3.25px;
    background-color: var(--secondery-black-color);
}
.AppsButton.White .Icon div{
    background-color: white;
}
.Icon {
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    grid-gap: 2px;
}
.AppsButton.White {
    background-color: rgba(255, 255, 255, 0.05);
}
.NotificationCount {
    width: 1.125rem;
    height: 1.125rem;
    background-color: rgb(255, 127, 80);
    border-radius: 50%;
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    font-size: 0.75rem;
    color: white;
    font-weight: 400;
}