.LazyLoadingFallback {
    min-height: calc(100vh - 5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.Loading {
    width: 6rem;
    height: 6rem;
}

.Text {
    color: var(--secondery-black-color);
    font-weight: 400;
    font-size: 1.25rem;
    margin-top: 2rem;
}