.ShareButton {
    margin-right: 0.25rem;
    position: relative;
    transition: all 0.3s ease;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    /* background-color: rgba(0, 0, 0, 0.025); */
}
.ShareButton .SVG {
    width: 50%;
    height: 50%;
    fill: var(--secondery-black-color);
}
.ShareButton:hover {
   cursor: pointer;
   background-color: rgba(225, 0, 255, 0.05);
    
}
.ShareButton:hover .SVG {
    
    fill: rgb(225, 0, 255);
     
 }