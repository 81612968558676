.Courses {
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgb(0, 0, 0 , 0.75);
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font-weight: 400;
}
.Courses:hover {
    /* box-shadow: var(--primary-black-shadow ); */
    cursor: pointer;
    color: rgb(0, 0, 0 , 1);

}
.Courses:hover .Arrow div {
 background-color: rgb(0, 0, 0);

}
.Courses.White {
    color: white;
}
.ListContainer {
    position: fixed;
    top: 6rem;
    right: 1rem;
    /* box-shadow: var(--primary-black-shadow ); */
   
    display: flex;
}
.Arrow div{
transition: inherit;
 width: 1.5px;
 height: 10px;
 background-color: rgb(0, 0, 0 , 0.75);

}
.Courses.White .Arrow div {
    background-color: white;
}
.Arrow {
    transition:all 0.3s ease ;
    margin-top: 5px;
    margin-right: 1rem;
    height: 100%;
   transform: rotateZ(-90deg);

}

.RightArrow {
    transform: rotateZ(45deg) translateX(2.5px);
   
}
.LeftArrow {
    transform: rotateZ(-45deg) translateX(2.5px);

}
.Courses:hover.Active {
    /* box-shadow: var(--primary-black-shadow ); */

}
.Active {
    color: var(--primary-color) !important;
    background-color: var(--primary-background-color);
    
}
.Active .Arrow div {
    background-color: var(--primary-color) !important;
}
.Courses.White.Active {
    background-color: rgba(255, 255, 255, 0.25);
    color: white !important;
}
.Courses.White.Active .Arrow div {
    background-color: rgb(255, 255, 255) !important;
}
@media (max-width: 900px) {
    .Courses {
        display: none;
    }
    .ListContainer {
        position: static;
        right: 0;
        top: 0;
        height: 100vh;
    }
}