.SVG {
    width: 50%;
    min-width: 20rem;
    position: relative;
}
.Notfound {
    padding: 3rem;
    width: 100%;
    min-height: calc(100vh - 5rem);
    margin-top: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}
.Link {
    transition: all 0.3s ease;
    text-decoration: none;
    font-weight: bold;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.025);
    /* box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.025); */
    color: var(--secondery-black-color);
}
.Link:hover {
    color: var(--primary-color);
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0);
    background-color: var(--primary-background-color);
}
.Title {
    color: rgb(230, 230, 230);
    font-size: 4rem;
    margin: 0;
    position: relative;
}
.Title::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    opacity: 0.5;
    background-color: white;
    animation: Type 5s ease  infinite alternate ;
}

@media (max-width : 550px   ) {
    .Title {
        font-size: 3rem;
    }
}
@keyframes Type {
    from {
        transform: translateX(-200%);
    }
    to {
        transform: translateX(366%);
    }
}