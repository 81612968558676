.CourseTopic {
    transition: all 0.3s ease;
    width: 16.5rem;
    height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: var(--primary-black-shadow );
    border: 2.5px solid rgba(0, 0, 0, 0);
    /* background-color: rgba(245, 245, 245, 0.2); */
    border-radius: 10px;
    padding: 1rem;
    margin: 1rem;
    animation: OnLoad 0.3s ease 0s 1;
}
.CourseTopic:hover {
    /* transform: scale(1.1); */
    border: 2.5px solid rgba(0, 0, 0, 0.025);
    cursor: pointer;

}
.ImageContainer {
    width: 100%;
    height: 10rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Image {
    height: 100%;
    border-radius: 10px;
}
.Title {
    text-align: right;
    font-size: 1.25rem;
}
.DetailsContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.CourseCountContainer {
    display: flex;
    align-items: center; 
}
.IconContainer {
    width: 1.75rem;
    height: 1.75rem;
    padding: 0.4rem;
    background-color: var(--primary-background-color);
    margin-left: 0.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.CourseCountContainer svg , .CourseTimeContainer svg {
    height: 100%;
    width: 100%;
}
.ContinueButton {
    transition: 0.3s all ease;
    width: 100%;
    /* padding: 1rem; */
    background-color: rgba(0, 0, 0, 0.025);
    padding: 0.5rem 0.25rem;
    border-radius: 10px;
    color: var(--primary-black-color);
}
.ContinueButton:hover {

    color: var(--primary-color);
    background-color: var(--primary-background-color);
}
.CourseTimeContainer {
    display: flex;
    align-items: center;
}
.CourseCount , .CourseTime {
    margin: 0.5rem 0;
    font-weight: normal;
    color: var(--secondery-black-color);
}
.ProgressContainer {
    width: 2rem;
}
.Line {
    position: relative;
    left: 1rem;
    width: calc(100% + 2rem);
    height: 1px;
    background-color: whitesmoke;
    
}
.ButtonAndProgressContainer {
    /* margin-top: 1rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    /* border-top: 1px solid whitesmoke; */
}
.ContinueButtonContainer {
    transition: all 0.3s ease;
    border-radius: 10px;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ContinueButtonContainer:hover {
    background-color: var(--primary-background-color);
    cursor: pointer;
}
.ContinueButtonContainer svg {
 width: 1rem;
 height: 1rem;
 fill: var(--primary-color);
  
}
.FullProgress {
    animation: FullProgressPop 0.3s ease-out 0s 1;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: rgba(0, 220, 90, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
}
.FullProgress svg  {
    width: 1rem;
    height: 1rem;
    fill: rgb(0, 220, 90);

}
@keyframes OnLoad {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes FullProgressPop {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    60%{
        opacity: 1;
        transform: scale(1.1);

    }
    100%{
        transform: scale(1);

    }
}