.SwiperSlide {
    transition: all 0.1s ease;
   background-color: rgba(0, 0, 0, 0);
   border-radius: 10px;
   
   display: flex;
   align-items: center;
   justify-items: center;
   justify-content: center;
   align-content: center;
   color: black;
   /* box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1); */
}
.SwiperSlide:hover {
    cursor: pointer;
    transform: scale(1.1) ;
    
}
.Slider {
    
    width: 100%;
    flex-direction: column;
   display: flex;
   align-items: center;
    /* margin: 3rem 0; */
    padding: 2rem 1rem;
    /* background-color: rgba(245, 245, 245, 0.2); */
}
.Swiper {
    width: 100%;
    
}
.SwiperSlider {
    padding: 1rem ;
}
.TextContainer {
    width: 100%;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: rgba(245, 245, 245, 0.2); */
    border-radius: 10px;
    margin: 0;
    padding: 1rem;
}
.Title {
    color: var(--primary-black-color);
    /* padding: 1rem; */
    margin: 0;
    /* text-shadow: 2px 2px 5px rgba(255, 127, 80, 0.5); */
}


.MoreButton {
transition: all 0.3s ease;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0.5rem 1rem;
border-radius: 10px;
}
.MoreButton:hover {
    background-color: var(--primary-background-color);
    cursor: pointer;
    
}
.MoreButton:hover .Link {
    color: var(--primary-color);
}
.MoreButton:hover .Arrow div {
    background-color: var(--primary-color);
}
.Link {
    color: black;
    text-decoration: none;
    font-weight: normal;
}

.Arrow {
    margin-right: 1rem;
    
 }
 .Arrow div{
     width: 1px;
     height: 8px;
     background-color: black;
    
    }
 
 
 .RightArrow {
     transform: rotateZ(45deg) translateX(2px);
    
 }
 .LeftArrow {
     transform: rotateZ(-45deg) translateX(2px);
 
 }
 