.IconContainer {
    transition: 0.3s all ease;
    border: 2.5px solid rgba(0, 0, 0, 0) ;
    width: 3rem;
    height: 3rem;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.IconContainer svg {
    width: 2rem;
    height: 2rem;
}
.Linkedin {
    background-color: rgba(0, 118, 181, 0.05);
}
.Linkedin:hover {
    border-color:rgba(0, 118, 181, 0.5) ;
}
.Telegram {
    background-color: rgba(3, 154, 229, 0.05);
}
.Telegram:hover {
    border-color:rgba(3, 154, 229, 0.5) ;
}
.Twitter {
    background-color: rgba(85, 172, 238, 0.05);
}
.Twitter:hover {
    border-color:rgba(85, 172, 238, 0.5) ;
}
.Whatsapp {
    background-color: rgba(76, 175, 79, 0.05);
}
.Whatsapp:hover {
    border-color:rgba(76, 175, 79, 0.5) ;
}
.Email {
    background-color:rgba(253, 58, 133, 0.05) ;
}
.Email:hover {
    border-color:rgba(253, 58, 133, 0.5) ;
}
.Phone {
    background-color:rgba(0, 224, 75, 0.05) ;
}
.Phone:hover {
    border-color:rgba(0, 224, 75, 0.5) ;
}
.Phone svg {
    fill: rgb(0, 224, 75) ;;
}
.Address {
    background-color:rgba(224, 0, 0, 0.05) ;
}
.Address:hover {
    border-color:rgba(224, 0, 0, 0.5) ;
}
.Address svg {
    fill: rgb(224, 0, 0) ;;
}
.Instagram svg {
    fill: white;
}
.Instagram {
   
   background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
   background-position: center;
   background-size: 120% 120%;
   background-repeat: no-repeat;
   border-color: rgb(255, 255, 255);

   
}
.Instagram:hover {
    border-color: rgba(255, 255, 255, 0.5);
}
.TooltipContainer {
    display: none;
    position: absolute;
    top: -3rem;
    z-index: 100;
}
.IconContainer:hover .TooltipContainer {
    display: block;
}