.MessageContainer {
    position: fixed;
    bottom: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000000;

}
.Message {
    background-color: rgb(0, 0, 0);
    padding: 1rem;
    width: 50%;
    border-radius: 10px;
    color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: animation 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
    min-width: 20rem;
}
.Message h1 {
    font-size: 1rem;
    margin: 0 1rem;
}
.Message svg {
    fill: whitesmoke;
    height: 1rem;

}
.Message:hover {
    background-color: rgb(0, 0, 0);

    cursor: pointer;

}
.Error {
    background-color: rgb(255, 40, 40);

}
.Error:hover {
    background-color:rgb(230, 40, 40) ;
}
.Success {
    background-color: rgb(0, 162, 255);

}
.Success:hover {
    background-color: rgb(0, 137, 216);

}
@keyframes animation {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;

    }
}