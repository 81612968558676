.CourseGridContainer {
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    grid-template-columns: repeat(5 , 1fr);
}
@media (min-width : 1750px) {
    .CourseGridContainer {
    grid-template-columns: repeat(6 , 1fr);

    }
}
@media (max-width : 1460px) {
.CourseGridContainer {
grid-template-columns: repeat(4 , 1fr);

}
}

@media (max-width : 1200px) {
.CourseGridContainer {
grid-template-columns: repeat(3 , 1fr);

}
}
@media (max-width : 900px) {
.CourseGridContainer {
grid-template-columns: repeat(2 , 1fr);

}
}
@media (max-width : 600px) {
    .CourseGridContainer {
    grid-template-columns: none;

    }
}