.Comments {
    overflow-x: hidden;
}
.NewComment {
    display: flex;
    height: 4rem;
    position: relative;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
}
.NewComment textarea {
    transition: all 0.3s ease;
    border: none;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.025);
    width: calc(100% - 5rem);
    padding: 1rem 2.5rem;
    
    
    max-height: 7.5rem;
    resize: none;

}
.NewComment textarea:focus {
    background-color: white;
    box-shadow: var(--primary-black-shadow );
    outline: none;
    
}
.ButtonsBar {
 
}
.EmojiContainer {
    position: absolute;
    right: 6.75rem ;
    top: 1.5rem;
    
}
.AddNewComment {
    transition: all 0.3s ease;
    position: absolute;
    left: 1.5rem;
    top: 1rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.25rem;
    z-index: 5;
    
    }
    .AddNewComment:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    
    }

.AddNewComment svg {
    fill: rgb(0, 0, 0);
    width: 1rem;
    height: 1rem;
    transform: rotateZ(180deg);
cursor: pointer;

}
.Avatar {
    width: 4rem;
    height: 4rem;

    border-radius: 50%;
    overflow: hidden;
    margin-left: 1rem;
}
.Line {
    position: relative;
   
    height: 1px;
    width: calc(100%);
    background-color: whitesmoke;
}
.Title {
    color: var(--primary-black-color);
    font-size: 1.75rem;
}
.SignInToComment {
    color: var(--secondery-black-color);
    text-align: right;
    text-decoration: none;
    font-weight: 400;
    font-size: 1.25rem;
    padding: 1.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    
}
.SignInToComment a {
    transition: 0.3s all ease;
    text-decoration: none;
    color: var(--primary-black-color);
    border-radius: 10px;
    font-weight: 500;
   

}
.SignInToComment a:hover {
    color: var(--primary-color);
   
}
.ShowMoreButton {
    transition: 0.3s all ease;
    margin-top: 1rem;
    background-color: rgba(0, 0, 0, 0.025);
    border-radius: 10px;
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-black-color);
    font-weight: 400;
}
.ShowMoreButton:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);

}
.Arrow div{
    transition: inherit;
     width: 1.5px;
     height: 10px;
     background-color: rgb(0, 0, 0 , 0.75);
    
    }
    .Arrow {
        transition:all 0.3s ease ;
        margin-top: 5px;
        margin-right: 1rem;
        height: 100%;
       transform: rotateZ(-90deg);
    
    }
    
    .RightArrow {
        transform: rotateZ(45deg) translateX(2.5px);
       
    }
    .LeftArrow {
        transform: rotateZ(-45deg) translateX(2.5px);
    
    }

.CommentsContainer {
    min-height: 21.75rem;
}
.EmptyBox {
    display: flex;
    flex-direction: column ;
    align-items: center;
}
.EmptyBox span {
    font-size: 1.25rem;
    margin: 0;
    font-weight: bold;
    position: relative;
    bottom: 1.5rem;
    padding:0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.025);
    color: var(--secondery-black-color);
    border-radius: 10px;
}