.Paragraph {
    color: var(--primary-black-color);
    padding: 0.5rem;
    text-align: justify;
    
}
.Paragraph a {
    color: var(--primary-blue-color);
    text-decoration: none;
}
.Loading {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 1.5rem;
    background-color: rgba(0, 0, 0, 0.0125);
    margin: 0.5rem 0;
}
.Loading.First {
   margin-top: 0;
}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -50%;
    top: 0;
    height: 100%;
    width: 50%;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;

  }
  @keyframes load {
    from {
        left: -50%;
    }
    to   {
        left: 100%;
    }
  }