.Footer {
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    bottom: 0;
    left: 0;
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);
    flex-direction: column;
    z-index: 10000;

}
.SocialAndLinks {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

}
.SocialMedia {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-self: center;
}
 .Link {
    color: var(--primary-black-color);
    padding: 0.5rem 1rem;
    text-decoration: none;
    margin: 0 0.125rem;
    transition: all 0.2s ease;
    border-radius: 10px;
 }
 .AllRightReserved {
    font-size: 0.75rem;
    color: var(--secondery-black-color);
    padding: 0.5rem 1rem;
    text-align: right;
    background-color: rgba(0, 0, 0, 0.0125);
    /* border-radius: 10px; */
    width: 100%;
    padding: 1rem 2rem;
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
}
.LogoContainer {
    height: 2rem;
}
 .SocialMedia .Link:hover {
     background-color: rgba(255, 255, 255, 0);
 }
 .Link:hover {
     color: var(--primary-color);
    background-color: var(--primary-background-color);

 }
 .Link svg {
    transition: all 0.2s ease;
     height: 1rem;
     width: 1rem;
     fill: black;
     margin-top: 1rem;
 }
 .Link:hover svg {
     fill: var(--primary-color);
 }
 .Line {
     width: calc(100%);
     background-color: rgba(0, 0, 0, 0.1);
     height: 1px;
     margin: 1rem 0;
     display: none;
 }
 @media (max-width: 900px) {
     .Footer {
         height: auto;
     }
     .SocialAndLinks {
         flex-direction: column;
     }
     .Links {
         display: flex;
         align-items: center;
         justify-content: space-around;
         flex-wrap: wrap;
     }
     .Link {
       font-size: 0.75rem;
       margin: 0 0.0625;
    padding: 0.25rem 0.5rem;

     }
    
    .Link svg {
        height: 0.75rem;
        width: 0.75rem;
     }
 }