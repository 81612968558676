.List {
    position: absolute;
    top: 6rem;
    left: 1rem;
    width: 17.5rem;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: var(--primary-black-shadow );
    padding: 1rem;
    animation: OnLoad 0.3s ease  1;

}
.List:hover {
    cursor: pointer;
}
.Line {
    height: 1px;
    width: 95%;
    background-color: whitesmoke;
    margin: 0.5rem 0;
}
.Avatar {
    width:7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    margin: 1rem auto;
    margin-bottom: 0;
    overflow: hidden;
}
.Email {
    font-size: 0.65rem;
    margin-top: 0.125rem;
    color: rgba(0, 0, 0, 0.25);
}
.Name {
    margin: 0;
    margin-top: 0.5rem;
}
.ButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.ButtonsContainer button {
    transition: all 0.3s ease;
    margin:  0;
    width: 100%;
    padding: 0.75rem 0.5rem;
    font-family: inherit;
    border-radius: 10px;
    border: none;
    font-weight: bold;
    color: var(--secondery-black-color);
    text-align: right;
    font-weight: normal;
    background-color: rgba(255, 127, 80, 0);
    display: flex;
    align-items: center;

}
.ButtonsContainer button:focus {
    outline: none;
    /* background-color: var(--primary-background-color); */

}
.Profile:hover  {
    background-color: var(--primary-background-color);
    color: var(--primary-color);
    cursor: pointer;
}
.Profile svg , .Logout svg{
    transition: all 0.3s ease;
    height: 0.85rem;
    margin-left: 0.5rem;
    fill: rgba(0, 0, 0, 0.1);
}
.Profile:hover svg {
    fill: var(--primary-color);
}
.Logout:hover svg {
    fill: red;
}
.Logout:hover {
    background-color: rgba(255, 0, 0, 0.05);
    cursor: pointer;
    color: red;
}
@keyframes OnLoad {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;

    }
}
@media (max-width : 900px){
    .List {
        position: static;
        box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0);
        width: 20rem;
        border: 1px solid whitesmoke;
        margin: auto;
        border-radius: 0;
        box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0);

    }
    .Avatar {
    width: 7.5rem;
    height: 7.5rem;
    }
    
    .ButtonsContainer button {
        margin: 0 0.5rem;
    }
}