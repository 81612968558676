.AppsListItem {
    transition: all 0.3s ease;
    width: 8rem;
    height: 8rem;
    padding:1rem;
    color: var(--secondery-black-color);
    text-decoration: none;
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0.25rem;
    
}
.AppsListItem:hover {
    /* background-color: var(--primary-background-color);
    color: rgba(255, 127, 80, 0.75); */
    /* box-shadow: var(--primary-black-shadow); */
}
.AppsListItem:hover .Title {
    /* color: var(--primary-color); */
}
.Title {
    display: block;
    font-weight: 300;
    margin-top: 0.25rem;
    transition: inherit;
}
.Text {
    font-weight: 500;
    margin: 0;
    font-size: 1.25rem;
    
}
.Dot {
    width: 0.5rem;
    height: 0.5rem;
    display: inline-block;
    margin-left: 0.25rem;
    border-radius: 2px;
    opacity: 0.75;
}
.image {
    height: 50%;
}