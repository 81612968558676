.VideoContainer {
    margin-left: 1rem;
    width: 100%;
    box-shadow: var(--primary-black-shadow );
    height: calc(calc(100vw - 30rem) / 16 * 9);
    max-height: calc(62rem / 16 * 9);
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.0125);
    position: relative;
}
.ContentTitle {
    margin: 0;
    color: var(--primary-black-color);
    font-weight: 450;
    text-align: center;
    justify-self: center;
    align-self: center;
    margin-right: 3rem;
    font-size: 1.5rem;
    
}
.ContentTitle.Loading {
    max-width: 15rem;
    width: 33%;
    height: 2.5rem;
    background-color: rgba(0, 0, 0, 0.0125);
    position: relative;
    overflow: hidden;
}
.ViewsAndTitleContainer , .ButtonsAndCourseDetailContainer {
    display: flex;
    /* flex-direction: row-reverse; */
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
    /* background-color: rgba(0, 0, 0, 0.0125); */
    /* box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.025); */
    border-radius: 0 0 10px 10px;
    
    margin: 1rem auto;
    max-width: 62rem;
}
.ViewsAndButtonsContainer {
    display: flex;
}
.Views {
    /* background-color: rgba(0, 0, 0, 0.025); */
    border-radius: 10px;
    color: var(--secondery-black-color);
    font-size: 0.75rem;
    /* height: 2rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: rgba(0, 0, 0, 0.025); */
    border-radius: 10px;
    /* padding: 0 0.5rem; */
    min-width: 2rem;
    height: 2rem;
    margin-left: auto;
    flex-direction: row-reverse;
}
.Views strong {
    margin:0 0.5rem;
}

.Views svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--secondery-black-color);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 0.5rem;
    

}
.ButtonsContainer {
    display: flex;
    margin-right: auto;
    /* margin-top: 1rem; */

}
.Loading{
    overflow: hidden;
}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -33%;
    top: 0;
    height: 100%;
    width: 33%;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;

  }
  .DescriptionTitle {
    font-weight: 400;
}
.Spiliter {
    height: 1px;
    width: calc(100%);
    background-color: whitesmoke;
    position: relative;
    
    margin: 1rem 0 ;
}
@keyframes load {
    from {
        left: -33%;
    }
    to   {
        left: 100%;
    }
  }
  @media (max-width : 900px) {
    
    /* .ViewsAndTitleContainer {
        flex-direction: column-reverse;
    } */
    .ViewsAndButtonsContainer {
        justify-content: space-between;
        width: 100%;
    }
    /* .ContentTitle {
        margin: 1rem 0;
    } */
 
  
  
    .VideoContainer {
        height: calc(100vw / 16 * 9);
        
    }
    
}
@media (min-width: 1600px) {
    
    .VideoContainer , .ViewsAndTitleContainer , .ButtonsAndCourseDetailContainer {
        max-width: none;
        max-height: calc(75rem / 16 * 9);

    }
    .ViewsAndTitleContainer {
        max-height: none;
    }
}
@media (max-width: 600px) {
    .ContentTitle {
        font-size: 1rem;
    }
    
}