.VideoItem {
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
    animation: OnLoad 0.3s ease 0s 1;
    
}
.CoverContainer {
    width: 100%;
    height: 11.25rem;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    z-index: 20;
    box-shadow: var(--primary-black-shadow );

}
.CoverContainer:hover .Cover {
    cursor: pointer;
    filter: brightness(0.9);

}
.CoverContainer:hover::before {
    color: rgba(255, 255, 255, 1);
}
.CoverContainer::before {
    transition: 0.3s all ease;
    content: "\f101";
    font-family: VideoJS;
    font-weight: normal;
    font-style: normal;
    font-size: 4rem;
    color: rgba(255, 255, 255, 0);
    position: absolute;
    z-index: 10;
    top: 3.75rem;
    right: 8rem;
    text-align: center;
    pointer-events: none;
}
.Cover {
    transition: all 0.3s ease;
    width: 100%;
    height: 100%;
    border-radius: 10px;

}
.DurationContainer {
    position: absolute;
    z-index: 10;
    bottom: 0.5rem;
    left: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.25);

}
.Duration {
    color: white;
    margin: 0;
    font-size: 0.75rem;
}
.Controls {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;

}
.TitlesContainer {
    display: flex;
    align-items: flex-start ;
    flex-direction: column;
    justify-content: space-between;
}
.TitlesContainer .Title {
    transition: all 0.3s ease;
    font-size: 1rem;
    margin: 0;
    color: var(--primary-black-color);
    text-decoration: none;
    font-weight: 500;
}
.TitlesContainer .Title:hover {
    color: rgb(0, 0, 0);

}
.TitlesContainer .Topic {
    transition: all 0.3s ease;
    text-decoration: none;
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.25);
}
.TitlesContainer .Topic:hover {
    color: var(--secondery-black-color);
}
/* .DetailsContainer {
    display: flex;
    align-items: center;
} */
.CourseImageContainer {
    position: absolute;
    z-index: 10;
    bottom: 0.5rem;
    right: 0.5rem;
    border-radius: 10px;
    /* background-color: rgba(255, 255, 255, 0.25); */

    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

}
.CourseImage {
    transition: 0.3s all ease;
    width: 1.75rem;
    height: 1.75rem;
    /* border-radius: 10px; */
    opacity: 0.75;
    border-radius: 5px;
}
.CourseImageContainer:hover .CourseImage {
    opacity: 1;

}
@keyframes OnLoad {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}