.swiper-button-prev , .swiper-button-next {
color: var(--secondery-black-color) !important;
width: 2.5rem !important;
height: 2.5rem !important;
/* text-shadow: 0 2px rgba(0, 0, 0, 0.4); */
transition: all 0.3s ease;
background-color: white;
border-radius: 50%;
box-shadow: var(--primary-black-shadow);
transform: translateY(-1rem);
}
.swiper-button-prev::after , .swiper-button-next::after {
    font-size: 1.25rem !important;
    margin: auto;
}
.swiper-button-prev::after {
    margin-right: 0.85rem;
}
.swiper-button-next::after {
    margin-left: 0.85rem;
}
.swiper-button-next:hover {
transform: translateX(-5px) translateY(-1rem);
color: var(--primary-black-color) !important;

}
.swiper-button-prev:hover {
color: var(--primary-black-color) !important;

    transform: translateX(5px) translateY(-1rem);
}
.swiper-pagination-bullet {
    background-color: rgb(245, 245, 245) !important;
    opacity: 1 !important;
    padding: 0.35rem;
    box-shadow: 0 1px 0px 0px var(--secondery-black-color);
    transition: all 0.1s ease;
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
}
.swiper-pagination-bullet:hover {
transform: scale(1.1);
}
.swiper-pagination-bullet-active {
    background-color: var(--primary-color) !important;
   
}
.swiper-button-disabled {
    opacity: 0 !important;
}
.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper-slide-active {
   
}