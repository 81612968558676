.VideoItemSkeleton {
    width: 20rem;
    margin: 1rem;
    height: 12.5rem;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.0125);
    position: relative;
    overflow: hidden;
}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -33%;
    top: 0;
    height: 100%;
    width: 33%;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
@keyframes load {
    from {
        left: -33%;
    }
    to   {
        left: 100%;
    }
} 