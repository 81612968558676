.Congratulations {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 500;
    pointer-events: none;

}
.Paper {
    width: 0.5rem;
    height: 1.5rem;
    background-color: black;
    position: absolute;
    animation: animation 3s ease 0s 1 ;
    border-radius: 1px;
    pointer-events: none;
}

@keyframes animation {
    0% {
        top: 0;
        opacity: 0.75;
    }
   
    100% {
        top: 150vh;
        transform: rotateZ(360deg);
        opacity: 0;
    }

}