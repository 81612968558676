.PlayingIcon {
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Box {
    border-radius: 10px;
}
.Box.Video , .Box.Text , .Box.Quiz{
    width: 2.5px;
    background-color: rgb(0, 89, 255);
    height: 100%;
}
.Box.Text {
    background-color: rgb(225, 0, 255);
}
.Box.Quiz {
    background-color: rgb(0, 192, 80);
}
.First {
    animation: Animation1 0.5s ease 0s alternate infinite ;
}
.Second {
    animation: Animation2 0.5s ease 0s alternate infinite ;

}
.Third {
    animation: Animation3 0.5s ease 0s alternate infinite ;

}
@keyframes Animation1{
    0% {
        transform: scaleY(0.5);
    }
    100% {
        transform: scaleY(1);

    }
}
@keyframes Animation2{
    0% {
        transform: scaleY(1);
    }
    100% {
        transform: scaleY(0.5);

    }
}
@keyframes Animation3{
    0% {
        transform: scaleY(0.5);
    }
    100% {
        transform: scaleY(1);

    }
}
