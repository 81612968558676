.Comment {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 1rem;
    overflow: auto;
}
.TopComment {
    display: flex;
    align-items: center;
}
.Data {
    width: calc(100% - 5rem);
    margin-right: 1rem;
}
.Avatar {
    width: 4rem;
    height: 4rem;
}
.Avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.Name {
    font-weight: 500;
    margin-left: 0.5rem;
}
.Elapse {
    font-weight: 400;
    color: var(--secondery-black-color);
    font-size: 0.8rem;
}
.CommentPara {
    color: var(--primary-black-color);
    word-wrap: break-word;
}
.BottomComment {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Replay , .Like {
    transition:all 0.3s ease;
    font-weight: 500;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.8);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: rgba(155, 68, 68, 0.025);
    position: relative;
    display: inline-block;
}
 .Like:hover {
    cursor: pointer;
    color: black;
    background-color: rgba(0, 0, 0, 0.05);

}
.TooltipContainer {
    position: absolute;
    top: -4rem;
    right: -0.25rem;
    display: none;
}
.Replay:hover .TooltipContainer, .Like:hover .TooltipContainer {
    display: block;
}
.Replay {
    /* display: none; */
    opacity: 1;
    background-color: 0.0125;
    color: rgba(0, 0, 0, 0.4);

}
.Replay:hover {
    cursor: not-allowed;
}
.Like {
    margin-right: 0.5rem;
}
.LikeCountsContainer {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.05);
}
.LikeCountsContainer svg{
    height: 0.8rem;
    width: 0.8rem;
    fill: rgb(0, 0, 0);
}
.LikeCountsContainer.Liked {
    background-color: rgba(255, 0, 76, 0.05);

}
.LikeCountsContainer.Liked svg {
    fill: rgb(255, 0, 76);

}
.LikeCounts {
    font-size: 0.8rem;
    margin-left: 0.25rem;
    margin-top: 0.125rem;
    color:rgb(0, 0, 0);
}
.Liked .LikeCounts {
    color:rgb(255, 0, 76);

}
