.UserType {
    border-radius: 10px;
    padding: 0.5rem;
    font-size: 0.75rem;
    margin: auto;
    margin-bottom: 0.5rem;
    width: 5rem;
    font-weight: bold
}

.Free {
    background-color: rgba(0, 149, 255, 0.05);
    color: rgb(0, 149, 255);
}
.SuperStaff {
    background-color: rgba(255, 0, 100, 0.05);
    color: rgb(255, 0, 100);
}
.Premium {
    background-color: rgba(212, 175, 55, 0.05);
    color:rgb(212,175,55);
}
.UnCategorized {
    background-color: rgba(0, 0, 0, 0.05);
    color: black;
}