.emoji-mart-anchor-selected {
    color: var(--primary-color) !important;
}
.emoji-mart-anchor-selected span.emoji-mart-anchor-bar {
    background-color: var(--primary-color) !important;
}
.emoji-mart-anchor:hover {
    cursor: pointer;
}
.emoji-mart-preview {
    height: 1rem;
}
.emoji-mart-preview-emoji , .emoji-mart-preview-data ,.emoji-mart-preview-skins  {
    display: none;
}
.emoji-mart-search-icon {
    left: 11px;
    right: unset;
    background-color: rgba(255, 255, 255, 0) !important;
}
.emoji-mart {
    border: none;
    box-shadow: var(--primary-black-shadow );
    border-radius: 10px !important;
}
.emoji-mart-bar , #emoji-mart-search-2 {
    border-color: whitesmoke;
}

#emoji-mart-search-2:focus {
    border-color: rgba(0, 0, 0, 0.125);
    box-shadow: var(--primary-black-shadow );

}
.emoji-mart .emoji-mart-emoji {
    transition:all 0.1s  ease;
}
.emoji-mart .emoji-mart-emoji:hover ,
.emoji-mart .emoji-mart-emoji:hover span {
    cursor: pointer !important;
    transform: scale(1.05);
}
.emoji-mart .emoji-mart-emoji:hover:before {
    background-color: rgba(0, 0, 0, 0);
}