.GoUpButtonContainer {
    position: fixed;
    right: 0rem;
    bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 5555;

}
.GoUpButton {
    pointer-events: all;

    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid whitesmoke;
    box-shadow: var(--primary-black-shadow );
    display: flex;
    align-items: center;
    justify-content: center;
    animation: onLoad 0.3s ease 0s 1 ;
}
.GoUpButton:hover {
    cursor: pointer;
}
.Arrow div{
    width: 1.5px;
    height: 10px;
    background-color: var(--secondery-black-color);
   
   }
   .Arrow {
       margin-bottom: calc(0.25rem + 1.5px);       margin-right: calc(1.25rem - 3px);
       height: 100%;
      transform: rotateZ(90deg);
   
   }
   
   .RightArrow {
       transform: rotateZ(45deg) translateX(2.5px);
      
   }
   .LeftArrow {
       transform: rotateZ(-45deg) translateX(2.5px);
   
   }
   
   @keyframes onLoad {
       0% {
        transform: translateY(100%);
        opacity: 0;
       }
       100% {
        transform: translateY(0%);
        opacity: 1;
       }
   }