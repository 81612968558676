.CoursePage {
    padding: 1rem;
    text-align: right;
    overflow: hidden;
}
.DescriptionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.Description {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    box-shadow: var(--primary-black-shadow );
    padding: 2rem;
    width: 40rem;
    margin-left: 1rem;
    /* border: 2.5px solid rgba(0, 0, 0, 0.025); */
    animation: OnLoadDescription 0.3s ease 0s 1;
    min-height: 35rem;

}
.Description , .VideoPlayerContainer {
    height: calc(calc(100vw - 40rem) / 16 * 10);

}

.Title.Loading {
    width: 100%;
    height: 2rem;
}
.Image {
    width: 10rem;
    border-radius: 10px;
}


.DetailsContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between
}
.CourseCountContainer {
    display: flex;
    align-items: center; 
    margin-left: 1rem;
    border-radius: 10px;
    /* background-color: rgba(255, 127, 80, 0.05); */
    /* padding: 0.5rem 1rem; */
}
.IconContainer {
    width: 2rem;
    height: 2rem;
    padding: 0.4rem;
    background-color: var(--primary-background-color);
    margin-left: 0.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.CourseCountContainer svg , .CourseTimeContainer svg {
    height: 100%;
    width: 100%;
}
.CourseTimeContainer {
    display: flex;
    align-items: center;
    border-radius: 10px;
    /* background-color: rgba(255, 127, 80, 0.05); */
    /* padding: 0.5rem 1rem; */
}
.CourseCount , .CourseTime {
    margin: 0.5rem 0;
    font-weight: normal;
    color: var(--secondery-black-color);
    font-size: 1rem;
}
.OverViewTitle {
    margin: 0;
    margin-top: 1rem;
    color: var(--primary-black-color);
}
.OverView {
    color: var(--secondery-black-color);
    max-height: 12rem;
    overflow: auto;
}
.VideoPlayerContainer {
    animation: OnLoadVideoPlayer 0.3s ease 0s 1;
    width: 100%;
    min-height: 25rem;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: var(--primary-black-shadow);
}
.Loading {
    position: relative;
    overflow: hidden;
    min-height: 20rem;
    background-color: rgba(0, 0, 0, 0.0125);

}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 33%;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;

  }
@media (max-width : 900px){
    .VideoPlayerContainer {
        min-height: fit-content;
        width: 100%;
        height: calc(calc(100vw - 2rem) / 16 * 10);
    }
    .DescriptionsContainer {
        flex-direction: column;
        width: 100%;
    }
    .Description {
        /* flex-direction: row; */
        align-items: center;
        width: 100%;
        height: auto;
        margin-left: 0;

        margin-bottom: 1rem;
    }
}

  @keyframes load {
    from {
        left: -33%;
    }
    to   {
        left: 100%;
    }
  } 
@keyframes OnLoadVideoPlayer {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
  }
  @keyframes OnLoadDescription {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
  }