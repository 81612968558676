.Logo {
    transition: all 0.3s ease;
    height: 50%;
    /* filter: invert(100%); */
    position: relative;
    /* z-index: 50000; */
}
/* .Logo:hover {
    transform: scale(1.1);

} */

/* .Academy {
    font-size: 1.5rem;
    font-weight: 400;
    height: 50%;
    color: rgba(255, 127, 80, 0.5);
    bottom: 0.25rem;
    left: 0.25rem;
    position: relative;
} */
.Link {
    transition: all 0.3s ease;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    text-decoration: none;
    position: relative;

}

.Hide {
    opacity: 0;
}
.White {
    filter: invert(1);
}
@media (max-width: 900px) {
    .Logo {
        height: 40%;
    }
}