.EmptyBox {
    padding: 1rem;
    max-width: 25rem;
    margin: 1rem auto;
}
.EmptyBox img {
    width: 12.5rem;
    height: 12.5rem;
}
/* .EmptyBox h1 {
    font-size: 1.5rem;
    color: var(--primary-black-color);
    font-weight: 400;
} */