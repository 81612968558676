.Bookmark {
    margin-right: 0.25rem;
    position: relative;

    transition: all 0.3s ease;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    /* background-color: rgba(0, 0, 0, 0.025); */
}
.Bookmark svg {
    width: 50%;
    fill: var(--secondery-black-color);
}
.Bookmark:hover , .SavedItem{
   cursor: pointer;
   background-color: rgba(0, 132, 255, 0.05);
    
}
.SavedItem:hover {
   background-color: rgba(0, 132, 255, 0.1);

}
 .Bookmark:hover svg , .Active svg{
    
    fill: rgb(0, 132, 255);
     
 }
 .TooltipContainer {
    display: none;
    position: absolute;
    top: -3rem;
}
.Bookmark:hover .TooltipContainer {
    display: block;
}