
.ResetPassword {
    min-height:  calc(100vh - 5rem);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center
}
.InputsContainer {
    background: rgb(255, 255, 255);
    /* border: 1px solid white; */
    /* backdrop-filter: blur(30px); */
    width: 100%;
    /* border-radius: 10px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 3rem;
    /* border-radius: 10px; */
    
    height: calc(100vh - 20rem);
    max-width: 40rem;
    box-shadow: var(--primary-black-shadow );
    /* border: 1px solid whitesmoke; */
    border-radius: 10px;
}
.Title {
    text-align: right;
    color: var(--primary-black-color);
    margin: 0;
}
.Inputs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width:  80%;
    
    /* padding: 1rem; */
}
.Input {
    transition: all 0.3s ease;
    padding: 1.25rem;
    font-size: 1rem;
    border-radius: 10px;
    border: none;
    margin: 0.5rem 0;
    /* margin: 1rem; */
    width: 100%;
    background-color: rgba(0, 0, 0, 0.025);
    color: rgb(80, 80, 80);
    /* border: 2.5px solid rgba(255, 127, 80, 0.0); */


}
.Input:focus {
    /* border: 2.5px solid rgb(255, 172, 142); */
    outline: none;
    background-color: white;
    box-shadow:var(--primary-black-shadow );
}
.Label {
    color: rgb(80, 80, 80);

    /* width: 100%; */
    text-align: right;
    font-weight: normal;
    margin: 1rem 0;
    font-size: 1rem;
}
.ErrorAndLabelContainer {
    width: 100%;
    display: flex;
    align-items: flex-start ;
    justify-content: space-between;
    flex-direction: column;
    /* padding: 1rem; */
}
.ErrorMessage {
    min-width: 1rem;
    min-height: 1rem;
    color: rgb(255, 119, 119);
    /* font-weight: bold; */
    font-size: 0.75rem;
    text-align: left;
}
.CallToAction {
    transition: all 0.3s ease;
    width: 80%;
    padding: 1rem;
    background-color: rgb(255, 127, 80);
    color: white;
    border-radius: 10px;
    font-family: inherit;
    border: 1.5px solid rgba(245, 245, 245, 0.2);
    font-size: 1rem;
    margin-top: 1rem;

}
.CallToAction:hover {
    /* background-color: white;
    color: black;
    border: 1.5px solid rgba(100, 100, 100, 0); */
    box-shadow: 0 0 15px 1px rgba(255, 127, 80, 0.5);

    cursor: pointer;
}
.CallToAction:focus {
    outline: none;
}
button:disabled {
    background-color: darkgrey;
    color: white;
}
button:disabled:hover{
    background-color: darkgrey;
    color: whitesmoke;
    border: rgba(245, 245, 245, 0) 1.5px solid;
    cursor: not-allowed;
}
.SuccessMessage {
    font-weight: 400;
    font-size: 1.5rem;
    color: var(--secondery-black-color);
}
@media (max-width : 450px){
   
    .InputsContainer {
        padding: 1.5rem;
    }
}