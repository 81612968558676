.AccountButton {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    /* margin-right: 1rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
}
.AccountButton svg {
    border: 2px solid black;
    height: 3rem;
    width: 3rem;
    padding: 1rem;
}
.AccountButton .Avatar {
    height: 3rem;
    width: 3rem;
}
.AccountButton .Avatar img {
    height: 3rem;
    width: 3rem;
}
.AccountButtonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 80%;
    padding: 0.25rem;
    /* padding-right: 1rem; */
    border-radius: 50%;
    font-weight: normal;
    color: rgb(0, 0, 0);
    transition: all 0.3s ease;
    margin-left: 0.5rem;
    max-height: 4rem;
    /* border: 1px solid rgba(0, 0, 0, 0); */
    /* background-color: rgba(245, 245, 245, 0.5); */
    /* border: 1px solid rgba(83, 51, 51, 0.1); */
   


}
.AccountButtonContainer:hover {

background-color: rgba(0, 0, 0, 0.025);
cursor: pointer;
}
.AccountButtonContainer:hover .AccountButton .Avatar {
    border-radius: 10px 0 0 10px;

}
.Name {
    color: var(--secondery-black-color);
    display: none;
}
.Loading  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0.25rem;
    padding-right: 1rem;
    border-radius: 50%;
    background-color: white;
    margin-left: 0.5rem;
    height: 3rem;
    width: 3rem;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.0125);
}
.Loading.White {
    background-color: rgba(255, 255, 255, 0.05);
}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
.Arrow div{
    width: 1px;
    height: 10px;
    background-color: var(--secondery-black-color);
   
   }
   .ArrowContainer {
       background-color: rgba(0, 0, 0, 0.025);
       padding: 0.25rem 1rem;
       /* height: 2rem; */
       margin-left: 1rem;
       margin-right: 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        display: none;
   }
   .Arrow {
       
       margin-top: 5px;
       /* margin-right: 1rem; */
       height: 100%;
      transform: rotateZ(-90deg);
   }
   
   .RightArrow {
       transform: rotateZ(45deg) translateX(2.5px);
      
   }
   .LeftArrow {
       transform: rotateZ(-45deg) translateX(2.5px);
   
   }
   
  @keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
} 
@media (max-width : 900px) {
    .ArrowContainer {
        display: block;
    }
    .AccountButtonContainer {
        /* display: none; */
        margin: 1rem auto;
        width: calc(100% - 2rem);
        max-width: none;
        padding-right: 1rem;
        border-radius: 10px;
    }
    .Loading {
        margin: auto;

    }
    .Name {
        display: block;
    }
}