.NotificationButton {
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 00.5rem;
    background-color: rgba(0, 0, 0, 0.025);
    border-radius: 10px;
    margin-left: 1rem;
    position: relative;
}
.NotificationButton:hover {
    cursor: pointer;
    background-color: var(--primary-background-color);
}
.NotificationButton:hover svg {
    fill: var(--primary-color);
}
.NotificationButton svg {
    transition: all 0.3s ease;

    height: 1.25rem;
    width: 1.25rem;
    fill: var(--secondery-black-color);
}
.NotificationButton.White svg {
    fill: white;
}
.NotificationButton.White {
    background-color: rgba(255, 255, 255, 0.05);
}
.NotificationCount {
    width: 1.125rem;
    height: 1.125rem;
    background-color: rgb(255, 127, 80);
    border-radius: 50%;
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    font-size: 0.75rem;
    color: white;
    font-weight: 400;
}