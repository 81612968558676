.ListItem , .ListItem a{
    color: black;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: normal;
    padding: 1rem 0.5rem;
    
    
}
.ListItemContainer.Active .ListItem img , .ListItemContainer:hover .ListItem img {
   
}
.ListItemContainer:hover .ListItem a , .ListItemContainer.Active .ListItem a ,.ListItemContainer:hover .ListItem {
cursor: pointer;
color: var(--primary-color);
transition: all 0.2s ease;

}
.ListItemContainer:hover , .ListItemContainer.Active {
    background-color: var(--primary-background-color);
    

}
 .ListItemContainer.Active .Arrow div , .ListItemContainer:hover .Arrow div {
    background-color: var(--primary-color);
}
.ListItemContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 10px;
    transition: all 0.3s ease;

}
.Arrow {
   margin-left: 1rem;
   
}
.Arrow div{
    width: 1px;
    height: 8px;
    background-color: black;
   
   }


.RightArrow {
    transform: rotateZ(45deg) translateX(2px);
   
}
.LeftArrow {
    transform: rotateZ(-45deg) translateX(2px);

}
.BackButton {
    position: relative;
    bottom: 0;
    left: 0;
    /* width: 5rem;
    height: 2.5rem; */
    border-radius: 10px;
    justify-content: flex-end;
    flex-direction: row-reverse;
    /* padding: 0.5rem 1rem; */
    /* margin-top: 4rem; */
    margin-bottom: 1rem;
    
    
}
.BackButton .Arrow {
    margin-left: 0;
    margin-right: 1rem;
}
.BackButton .Arrow div {
    background-color: var(--secondery-black-color);
}
.BackButton:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.025); 
}
.BackButton:hover .ListItem {
    color: black;
}
.BackButton:hover .Arrow div {
    background-color: black;
}
.BackButton .ListItem {
 width: auto;
 color: var(--secondery-black-color);
 font-weight: bold;
 /* margin-right: 0.25rem; */
 /* display: none; */
}
.BackButton .Arrow .RightArrow , .BackButton .Arrow .LeftArrow  {
    background-color: black;
}
.BackButton .Arrow {
    transform: rotateZ(180deg) translateX(-0.25rem);
}