.InputsContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50000;
    pointer-events: none;
   }
   .Search {
       transition: all 0.3s ease;
       box-shadow: var(--primary-black-shadow );
       border: none;
       padding: 1rem 1.5rem;
       border-radius: 10px;
       margin-left: 1rem;
       font-family: inherit;
       margin: 1rem;
       height: 4rem;
       width: 100%;
       pointer-events: all;
   }
   .Search:focus {
       outline: none;
       background-color: white;
       
   }
   .SearchContainer {
       display: flex;
       align-items: center;
       position: relative;
       bottom: 2.75rem;
       width: 25rem;
       max-width: calc(100% - 2rem);
   }
   .Icon  {
    transition: fill 0.3s ease;
    
    position: absolute;
    left:2rem;
    top: 2.5rem;
    width: 1rem;
    fill: black;
    z-index: 5;
    }
     