.List , .ChildList {
    background-color: white;
    list-style: none;
    margin: 0;
    padding: 1rem;
    text-align: right;
    border-radius: 5px;
    width: 20rem;
    overflow: auto;
    height: calc(100vh - 2rem - 5rem);
    max-height: 40rem;
    box-shadow: 15px 0 15px 2px rgba(0, 0, 0, 0.025);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    animation: OnLoad 0.3s ease  1;
    
}
.List {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}
.ChildList{
    border-right: 2.5px solid whitesmoke;
    box-shadow: -15px 0 15px 2px rgba(0, 0, 0, 0.025);

}
.Link {
    width: 100%;
    height: 100%;
    vertical-align: middle;

}
.ListHover {
    border-radius: 0 5px 5px 0;
}
.ChildListHover {
    border-radius: 5px 0 0 5px;
}
.Icon {
    height: 1rem;
    margin: 0 0.5rem;
    vertical-align: middle;
    display: none; 
}
.Loading {
    color: rgba(5, 0, 0, 0);
    width: 100%;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: normal;
    padding: 1rem 0.5rem;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 1rem;
    background-color: rgba(0, 0, 0, 0.0125);

}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
} 
@keyframes OnLoad {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}
@media (max-width: 900px) {
    .ChildList {
        position: fixed;
        top: 0;
        right: 0;
        min-height: 100vh;
        z-index: 2000;
    }
    .List {
        max-height: none;
        height: 100vh;
        box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0);

    }
}