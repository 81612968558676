.TagContainer {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: rgba(0, 0, 0, 0.025);
    padding: 0rem 0.5rem;
    border-radius: 10px;
    transition: all 0.3s ease;

}
.Tag {
    color: var(--secondery-black-color);
    font-size: 0.9rem;
}
.TagContainer:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
.TagContainer:hover .Tag {
    color: var(--primary-black-color);

}
.Loading {
    
    transition: all 0.3s ease;
    color: rgba(0, 0, 0, 0);
    text-decoration: none;
    width: 3.5rem;
    height: 1.5rem;
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.0125);

}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
} 