.MultipleChoice {
    box-shadow: var(--primary-black-shadow);
    padding: 1rem 2rem;
    border-radius: 10px;
    margin: 1rem 0;
    transition: border-width 0.3s ease ;
    
}
.Title {
    color: var(--primary-black-shadow);
    font-weight: 450;
    font-size: 1.4rem;
}
.Choices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}
.Line {
    width: 100%;
    height: 1px;
    background-color: whitesmoke;
}

.Wrong , .Right{
    border-right: 10px solid rgba(255, 80, 80, 0.5);
    /* box-sizing: 0 0 15px 5px rgb(255, 80, 80); */
}
.Right {
    border-color: rgba(0, 190, 101, 0.5);
}
.Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-direction: column-reverse; */

}
.Content img {
    width: 50%;
    position: relative;
    right: 1rem;
    /* margin-right: rem; */
    border-radius: 10px;
    /* box-shadow:var(--primary-black-shadow) ; */
}
.Title.Loading {
    width: 12.5rem;
    height: 2.5rem;
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.0125);
  }
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -33%;
    top: 0;
    height: 100%;
    width: 33%;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
  @keyframes load {
    from {
        left: -33%;
    }
    to   {
        left: 100%;
    }
  } 
@media (max-width : 750px) {
    .Content {
        flex-direction: column-reverse;
    }
    .Content img {
        width: 100%;
        right: 0rem;

    }
}
@media screen and (max-width : 1300px) and (min-width : 900px)  {
    .Content {
        flex-direction: column-reverse;
    }
    .Content img {
        width: 100%;
        right: 0rem;

    }
}