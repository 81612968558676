.ContentGridContainer {
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    grid-template-columns: repeat(4 , 1fr);
    
}
@media (min-width : 1750px) {
    .ContentGridContainer {
    grid-template-columns: repeat(5 , 1fr);

    }
}
@media (max-width : 1400px) {
   .ContentGridContainer {
   grid-template-columns: repeat(3 , 1fr);

   }
}

@media (max-width : 1200px) {
   .ContentGridContainer {
   grid-template-columns: repeat(2 , 1fr);

   }
}

@media (max-width : 700px) {
   .ContentGridContainer {
   grid-template-columns: none;


}
}