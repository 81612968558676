.CourseModule {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  box-shadow: var(--primary-black-shadow );
  padding: 1rem;
  border-radius: 10px;
  margin:1rem 0;
  margin-top: 0;
  /* border: 1px solid whitesmoke; */
  width: 100%;
}
.Title.Loading {
  width: 12.5rem;
  height: 2.5rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.0125);
}
.CourseModule.VideoPageCourseModule {
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0);
  padding: 0;
}
.CourseModule.Loading {
  height: 15rem;
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.0125);


}
.CourseModuleItem.Loading {
  height: 4rem;
  border-radius: 10px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.0125);
  position: relative;
  overflow: hidden;
}
.CourseModule.VideoPageCourseModule.Loading {
  height: 100%;
}
.Loading::before {
  content: '';
  display: block;
  position: absolute;
  left: -33%;
  top: 0;
  height: 100%;
  width: 33%;
  background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

.VideoPageCourseModule .Top {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 2.5rem 1fr 2.5rem;
  padding: 1rem 0;
  padding-top: 0;

  position: sticky;
  top: -1rem;
  background-color: white;
  z-index: 2;
}
.VideoPageCourseModule .Title {
  text-align: center;

}
.Title {
    padding: 1rem;
    color: var(--primary-black-color);
    font-size: 1.75rem;
    margin: 0;
    /* filter: drop-shadow(1px  1px 1px rgba(0, 0, 0, 0.13)); */
}
.CourseModule.VideoPageCourseModule .Title {
  padding: 0;
  margin-top: 0;
}
.Line {
  width: calc(100%);
  height: 1px;
  background-color: whitesmoke;
  margin: 0.5rem auto;
  position: relative;
  /* left: 1rem; */
}
@keyframes load {
  from {
      left: -33%;
  }
  to   {
      left: 100%;
  }
} 
@media (max-width : 900px) {
  .CourseModule {
    width: 100%;
  }
}