.ShareButtonModal {
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 2.5rem;
    width: 20rem;
    height: 10rem;
    box-shadow: var(--primary-black-shadow );
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10000;
    padding-top: 1rem;
    animation: onLoad 0.3s ease 0s 1;
    transform-origin: bottom left;

}
.ShareButtonModal.Left {
    left: unset;
    right: 0;
    transform-origin: bottom right;

}
.Line {
    width: 95%;
    height: 1px;
    background-color: whitesmoke;
    margin: auto;
    
}
.ShareButtonsContainer {
    display: flex; 
    width: 100%;
    height: 5rem;
}
.Top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}
.ShareIconAndTitleContainer {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.Title {
    text-align: center;
    margin: 0;
    font-size: 1.5rem;
    color: var(--primary-black-color);
    font-weight: bold;
}
.ShareIcon {
    margin-left: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    /* background-color: rgba(0, 0, 0, 0.025); */
}
.ShareIcon svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--primary-black-color);
}
.CopyToClipboard {
    position: relative;
    transition: all 0.3s ease;
    width: 3rem;
    height: 3rem;
    background-color: rgba(0, 0, 0, 0.025);
    border: 2.5px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.CopyToClipboard svg {
    transition: all 0.3s ease;
    fill: var(--secondery-black-color);
    width: 1.5rem;
    height: 1.5rem;
}
.CopyToClipboard:hover {
    border-color: var(--secondery-black-color);
}
.CopyToClipboard:hover .CopyToClipboardTooltip {
    display: block;
}
.CopyToClipboardTooltip {
    display: none;
    position: absolute;
    right: -5rem;
    padding: 0.5rem;
    background-color: white;
    border-radius: 10px;
    color: black;
    font-size: 0.7rem;
    width: 4rem;
    font-weight: 400;
    box-shadow: var(--primary-black-shadow );
}
.CopyToClipboardTooltip span {
    position: relative;
    z-index: 2;
}
.CopyToClipboardTooltip::after {
    content: "";
    width: 1rem;
    height: 1rem;
    background-color: white;
    position: absolute;
    left: 0;
    transform: rotateZ(45deg);
    z-index: 1;
}
.ShareButton {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@keyframes onLoad {
    from {
        transform: scale(0 , 0);
    }
    to {

        transform: scale(1,1);

    }
}
