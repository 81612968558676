.Topics {
    display: grid;
    grid-template-columns: repeat(6 ,1fr);
    align-items: center;
    justify-content: center;
    padding: 1rem;
    
}
.TopicsContainer {
    padding: 1rem;
    /* background-color: rgba(245, 245, 245, 0.2); */
    
}
.TopicsContainer h1 {
    text-align: right;
    padding: 1rem;
    
}
.Loading {
    padding: 0.5rem;
    border-radius: 10px;
    transition: all 0.3s ease;
    color: rgba(0, 0, 0, 0);
    text-decoration: none;
    height: 12.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 0.5rem;
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.0125);
}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
} 
@media (max-width : 1600) {
    .Topics {
        grid-template-columns: repeat(6 ,1fr);
    
    }
}
@media (max-width: 1200px) {
.Topics {
    grid-template-columns: repeat(5 ,1fr);

}
}
@media (max-width: 900px) {
    .Topics {
        grid-template-columns: repeat(4 ,1fr);
    
    }
    }
@media (max-width: 700px) {
    .Topics {
        grid-template-columns: repeat(3 ,1fr);
        
    }
    }
@media (max-width: 500px) {
    .Topics {
        grid-template-columns: repeat(2 ,1fr);
        
    }
    }