.Navigation {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 1);
    /* backdrop-filter: blur(5px); */
    color: black;
    padding: 1rem;
    position: fixed;
    left: 0;
    top: 0;
    /* box-shadow: 0 0 15px 5px var(--secondery-black-color); */
    z-index: 100000;
    /* box-shadow: 0 0 15px 2px whitesmoke; */
    /* backdrop-filter: blur(50px); */
    border-bottom: 2px solid whitesmoke;
    transition: all 0.3s ease;
}
.HomeNavigation {
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0);

}
.RightSide {
    display: flex;
    width: 30%;
    min-width: 15rem;
    align-items: center;
    justify-content: flex-start;
}
.LeftSide {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media (max-width: 900px) {
    .LeftSide {
        display: none;
    }
    .RightSide {
        width: 70%;
    }
}