.loader,
.loader:after {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  max-width: 15rem;
  max-height: 15rem;
  /* background-color: rgba(0, 0, 0, 0.05); */
}
.loader {
  /* margin: 60px auto; */
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.025);
  border-right: 1.1em solid rgba(0, 0, 0, 0.025);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.025);
  border-left: 1.1em solid var(--primary-black-color);
  
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
