.ArticleContent {
    overflow-x: hidden;
    box-shadow: var(--primary-black-shadow);
    border-radius: 10px;
    margin-top: 1rem;
    z-index: 5;
}

.ArticleContent h2 {
    font-size: 1.25rem;
    color: var(--primary-black-color);
} 
.ArticleContent article {
    padding: 2rem;
    
}
.ArticleContent p {
    text-align: justify;
    font-family: IranSans !important;
}
.ArticleContent span {
    text-align: justify;
    font-family: IranSans !important;
}
.ArticleContent p img {
    max-width: 100%;
    margin: 1rem auto !important;
}

.ArticleContent p a {
    color: var(--primary-blue-color);
    text-decoration: none;
}
.TopContainer {
    display: flex;
    /* align-items: center; */
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-shadow: var(--primary-black-shadow);
    padding : 1rem;
    margin: 0.5rem auto;
    margin-top: 0;
    border-radius: 10px;
}
.DetilsAndButtons {
    margin-left: 1rem;
    padding: 1rem;
    /* border-radius: 10px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid whitesmoke;
}
.Title {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 1rem;
    color: var(--primary-black-color);
    min-height: 2.5rem;
}
.Title.Loading {
    max-width: 15rem;
}
.DescriptionTitle {
    margin: 0;
    color: var(--primary-black-color);
    font-size: 1rem;
    padding-right: 0.5rem;
}
.Description {
    max-height: 12.5rem;
    overflow: auto;
    margin: 1rem 0 ;
}
.Description p {
    margin: 0;
}
.CoverImageContainer {
   width: 100%;
   height: 100%;
   min-height: 20rem;
   border-radius: 10px;
   display: flex;
   align-items: center;
   
}
.ArticleCover{
    width: 100%;
   border-radius: 10px;

}
.ButtonsContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    
}
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.ArticleContent pre code{
    border-radius: 5px;
    direction: ltr !important;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;

}
.ArticleContent pre code::selection {
	background-color: black;
	color: white;
}

.ArticleContent pre code::-webkit-scrollbar {
    padding: 0.125rem;
    height: 0.75rem;
}

.ArticleContent pre code::-webkit-scrollbar-thumb {
	transition:all 0.3s ease;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2.5px;
}
.ArticleContent pre code::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.2);

}
.ArticleContent pre code::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.05);

}
.ButtomPart {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ArticleContent pre:hover .CopyBtn {
    opacity: 0.25;
}
.CopyBtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 10px;
    background-color: rgb(100, 100, 100);
    border: none;
    font-family: IranSans;
    font-weight: bold;
    opacity: 0;
    transition: all 0.3s ease;

}
.CopyBtn::before , .CopyBtn::after {
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    /* background-color: white; */
    background-color: rgb(100, 100, 100);
    transition: height 0.3s ease 0s;
    border: 2px solid white;
    border-radius: 5px;
    position: absolute;
}
.CopyBtn::before {
    left: 0.325rem;
    top: 0.325rem;
}
.CopyBtn::after {
    bottom: 0.325rem;
    right: 0.325rem;
    /* box-shadow: -1px 0 15px 1px rgba(0, 0, 0, 0.5); */
}
.ArticleContent pre .CopyBtn:hover {
    opacity: 0.75;
    cursor: pointer;
}
.ArticleContent pre .CopyBtn:focus {
    opacity: 1;
    cursor: pointer;
    background-color: rgb(0, 49, 33);
}
.CopyBtn:focus::before , .CopyBtn:focus::after {
    border: none;
    background-color: white;
    height: 0.3rem;
    background-color: rgb(0, 255, 170);
}
.CopyBtn:focus::after{
    width: 1.25rem;
    transform: rotateZ(-45deg);
    right: 0.175rem;
    bottom: 0.80rem;
}
.CopyBtn:focus::before{
    width: 0.75rem;
    transform: rotateZ(45deg);
    top: unset;
    bottom: 0.65rem;
    left: 0.25rem;
}
.CopiedTooltip {
    position: absolute;
    opacity: 0;
    padding: 0.25rem;
    background-color: rgb(50, 50, 50);
    bottom: 0.125rem;
    right: 2.5rem;
    color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    font-weight: normal;
    pointer-events: none;
    transition: inherit;
}
.CopyBtn:focus .CopiedTooltip{
    opacity: 1;
}
@media (max-width : 900px) {

    .TopContainer {
        justify-content: space-between;
        width: 100%;
    }
   
  
  
    .ArticleCover {
        height: calc(100vw / 16 * 9);
        
    }
    .ArticleContent article {
        padding: 1rem;
    }
    
}
.Loading{
    overflow: hidden;
    position: relative;
   background-color: rgba(0, 0, 0, 0.0125);

}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -33%;
    top: 0;
    height: 100%;
    width: 33%;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;

  }

@keyframes load {
    from {
        left: -33%;
    }
    to   {
        left: 100%;
    }
  }
@media (max-width : 1200px) {
    
    .CoverImageContainer {
        min-height: unset;
    }
    .CoverImageContainer.Loading {
        min-height: 15rem;
    }
    .TopContainer {
        flex-direction: column-reverse;
    }
    .ArticleCover {
        width: 100%;
    }
    .DetilsAndButtons {
        margin-left: 0;
        margin-top: 1rem;
        border-left: 0px solid whitesmoke;
        /* border-top: 1px solid whitesmoke; */

    }
}
/* @media (min-width: 1600px) {
    
    .ArticleCover , .TopContainer {
        max-width: 75rem;
        max-height: calc(75rem / 16 * 9);

    }
    .TopContainer {
        max-height: none;
    }
} */
@media (max-width: 600px) {
    .Title {
        font-size: 1.5rem;
    }
    
}