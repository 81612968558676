.Home {
    position: relative;
    bottom: 5rem;
}
.Top {
    height: calc(50vh + 5rem);
    /* min-height: 30rem; */
    height: 100vh;
    background: 
    linear-gradient(rgba(0, 0, 0, 0.75),transparent , white),
    url('./img/background.jpg')
    ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
   
    
}
.TopData {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    /* backdrop-filter: blur(5px); */
    padding: 0 1rem;
    z-index: 5;
}
.Particles {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.Top .SearchInput {
    transition: all 0.3s ease;
    /* background-color: rgba(245, 245, 245, 0.5); */
    border: none;
    padding: 1rem 1.5rem;
    padding-left: 5rem;
    border-radius: 10px;
    margin-left: 1rem;
    font-family: inherit;
    margin: 1rem;
    box-shadow: var(--primary-black-shadow);
    width: 100%;
    height: 5rem;
    position: relative;
    background-color: rgba(0, 0, 0, 0.25);
    color: white;
    font-size: 1rem;
}
.Top .SearchInput:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.75);

}
.SearchInput::placeholder {
    color: white;
}
.InputContainer {
    position: relative;
    width: 50rem;
    max-width: calc(100%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.InputContainer svg {
    position: absolute;
    fill: white;
    left: 3rem;
    /* top: 6rem; */
    width: 1rem;
    height: 1rem;
    z-index: 5;
    transition: all 0.3s ease;
}
.InputContainer svg:hover {
    fill: black;
    cursor: pointer;
}
.Top h1 {
    /* text-shadow: 0px 7.5px black; */
    color: white;
    font-size: 3.5rem;
    position: relative;
    bottom: 1rem;
    font-weight: 800;
    width:100%;
    /* max-width: 40rem; */
    text-align: center;
    margin: 0;
}
.Top p {
    color: white;
    margin: 0;
    position: relative;
    /* bottom: .5rem; */
    /* max-width: 30rem; */
    /* display: none; */
}
.BlogAndAbout {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 3rem 0;
}
.Blog , .About{
    border-radius: 10px;
    transition: all 0.3s ease;
    width: 45%;
    height: 20rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(225, 0, 255, 0.05);
    position: relative;
    cursor: pointer;
}
.About {
    background-color: rgba(0, 140, 255, 0.05);
}
.AboutImage , .BlogImage {
    fill: rgba(0, 140, 255);
    height: 10rem;
    transition: inherit;
}
.BlogImage {
   
    fill: rgb(225, 0, 255);
}
.Blog h1 , .About h1{
    margin: 1rem;
    transition: all 0.3s ease;
    
}
.About h1 {
    color: rgba(0, 140, 255);
}
.Blog h1 {
    color: rgb(225, 0, 255);
}
.BlogButton  svg , .AboutButton svg{
    transition: all 0.3s ease;
    fill: rgb(225, 0, 255);
    width: 1rem;
    transform: rotateZ(180deg);
}
.AboutButton svg {
    fill: rgba(0, 140, 255);
}
.BlogButton  , .AboutButton{
    transition: all 0.3s ease;
    padding: 1rem 1.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 0 0 10px;
    
}
.BlogButton {
    border-radius:0 10px 10px 0;

}
.Blog:hover .BlogButton svg , .About:hover .AboutButton svg {
    transform: rotateZ(180deg) translateX(0.5rem);
}

.Blog:hover .BlogImage , .About:hover .AboutImage{
    transform: scale(1.1);

}

.Line {
    width: 95%;
    height: 1px;
    background-color: whitesmoke;
    margin: auto;
}
@media (max-width : 900px ) {
    .BlogAndAbout {
        flex-direction: column;
    }
    .Blog  , .About{
        margin: 1rem 0;
        width: 90%;
    }
    .AboutImage , .BlogImage {
        height: 7.5rem;
    }
    
  
    .Top h1 {
        font-size: 2rem;
    }
    .Top p {
        font-size: 0.75rem;
    }

    .Top .SearchInput {
        height: 4.5rem;
        font-size: 1rem;
    }
    .InputContainer svg {
        /* top: 5.7rem; */
        left: 2.5rem;
        width: 1.25rem;
    }
}