.Topic {
    padding: 0.5rem;
    /* border-radius: 10px; */
    transition: all 0.3s ease;
    color: black;
    text-decoration: none;
    height: 12.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 0.5rem;
    /* filter: opacity(0.5); */

}
.Topic h3 {
    text-align: center;
    font-weight: 500;
}
.Topic img {
    width: 64px;
    height: 64px;
}
.Topic:hover {
    /* background-color: rgba(255, 127, 80, 0.05); */
    cursor: pointer;
    /* border-bottom: 5px solid var(--primary-color); */
    filter: opacity(1);
    transform: translateY(-0.25rem);
    /* border: 2.5px solid rgba(0, 0, 0, 0.1); */
    border-radius: 10px;
    box-shadow: var(--primary-black-shadow );
}



