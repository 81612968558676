.Choice {
    margin:0.5rem;
    padding: 0.5rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    position: relative;
    min-height: 4rem;

}

.Choice input {
    transition: all 0.3s ease;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    appearance: none;
    -webkit-appearance: none;
    background-color: rgba(0, 0, 0, 0);
    margin: 0;
    border-radius: 10px;
    border: 1px solid rgba(255, 127, 80, 0);
}
.Choice p {
    color: var(--primary-black-color);
}
.Choice input:hover{
    border-color:rgba(0, 0, 0, 0.05);
    background-color: rgba(0, 0, 0, 0.025);
    cursor: pointer;
}
.Choice input:disabled  {
    /* border-color: rgb(0, 0, 0); */
    background-color: rgba(255, 127, 80, 0);
}
.Choice input:disabled:hover {
    cursor: not-allowed;
}
.Choice input:disabled ~  p ,
 .Choice input:disabled:hover ~ p{
    color: rgb(0, 0, 0);
}
.Choice input:disabled ~ label ,
 .Choice input:disabled:hover ~ label {
    background-color: rgba(0, 0, 0, 0.025);
    color: var(--secondery-black-color);
}
.Choice input:checked  {
    border-color: coral;
    background-color: rgba(255, 127, 80, 0.125);
}
.Choice input:checked ~  p ,
/* .Choice input:hover ~ p, */
.Choice input:checked:hover ~ p
 {
    color: coral;
}
.Choice input:checked ~ label ,
/* .Choice input:hover ~ label, */
.Choice input:checked:hover ~ label  {
    background-color: rgba(255, 127, 80, 0.125);
    color: coral;
}
.Choice input:checked:disabled  {
    border-color: rgba(255, 80, 80, 0);
    background-color: rgba(255, 80, 80, 0.125);
}
.Choice input:checked:disabled ~ p {
    color: rgb(255, 80, 80);
    
}
.Choice input:checked:disabled ~ label {
    color: rgb(255, 80, 80);
    background-color: rgba(255, 80, 80, 0.125);
  
}
.Choice.answer input:checked:disabled {
    border-color: rgba(0, 190, 101, 0);
    background-color: rgba(0, 190, 100, 0.125);
}
.Choice.answer input:checked:disabled ~ p {
    color: rgb(0, 190, 100);
    
}
.Choice.answer input:checked:disabled ~ label {
    color: rgb(0, 190, 100);
    background-color: rgba(0, 190, 100, 0.125);
}
.Choice label {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-left: 1rem;
    transition: all 0.3s ease;
    right: 2rem;
    background-color: rgba(0, 0, 0, 0.025);
    border-radius: 10px;
    color: var(--secondery-black-color);
}
.Choice p {
    transition: all 0.3s ease;
}
.Loading {
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.0125);
    pointer-events: none;
}

.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -33%;
    top: 0;
    height: 100%;
    width: 33%;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
  .Choice.Loading input:hover  {
    border-color: rgba(0, 0, 0, 0) !important;
    background-color: rgba(0, 0, 0, 0.0125) !important;
    cursor: auto;

  }
  .Choice input:disabled {
    border-color: rgba(0, 0, 0, 0) !important;

  }
  @keyframes load {
    from {
        left: -33%;
    }
    to   {
        left: 100%;
    }
  } 