.LoadingPage {
    height: 100vh;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    position: fixed;
    top: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5000000000;
    
}
.Loading {
    width: 6rem;
    height: 6rem;
}
.LoadingPage h1 {
    color: black;
    font-weight: bold;
    margin: 0;
    position: relative;
}

.LoadingPage p {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    color: var(--secondery-black-color);

}
