.SideDrawer {
    max-height: 100vh;
    position: fixed;
    z-index: 500222;
    background-color: white;
    display: none;
    top: 0;
    right: 0;
    box-shadow: var(--primary-black-shadow );
    overflow: auto;
    animation: OnLoad 0.3s ease  1;
   
}
.LogoContainer {
    display: flex;
    height: 5rem;
    padding: 0.5rem;
    flex-direction: row;
    justify-content: center;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}
@keyframes OnLoad {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1  ;
    }
}
@media (max-width: 900px) {
    .SideDrawer {
        display: block;
    }
}
/* @media (min-width : 425px) {
    .SideDrawer {
        width: 100%;
    }
} */