.Tooltip {
    padding: 0.5rem;
    background-color: white;
    border-radius: 10px;
    color: black;
    font-size: 0.7rem;
    min-width: 4rem;
    max-width: 6rem;
    text-align: center;
    font-weight: 400;
    box-shadow: var(--primary-black-shadow );
}
.Tooltip span {
    position: relative;
    z-index: 2;
}
.Tooltip::after {
    content: "";
    width: 1rem;
    height: 1rem;
    background-color: white;

    position: absolute;
    bottom: 0;
    left: calc(50% - 0.5rem);
    transform: rotateZ(45deg);
    z-index: 1;
}