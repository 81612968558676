.ArticleItem {
    width: 18rem;
    box-shadow: var(--primary-black-shadow);
    border-radius: 10px;
    overflow: hidden;
    padding: 1rem;
    margin: 1rem;
    animation: OnLoad 0.3s ease 0s 1;
    
}
.Details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    height: 14rem;
}
a {
    text-decoration: none;
}
.CoverContainer {
    position: relative;

}
.Cover {
    width: 100%;
    height: 9rem;
    border-radius: 5px;
}
.Title {
    color: var(--primary-black-color);
    font-size: 1.25rem;
    text-decoration: none;
    font-weight: 500;
    margin: 0.5rem 0;
    text-align: right;
    width: 100%;
}
.TitlesContainer {
    display: flex;
    align-items: center;
}
.DescriptionContainer {
    max-height: 10rem;
    overflow: auto;
    margin: 0.5rem 0;
    font-size: 0.8rem;
    text-align: justify;
    color: var(--secondery-black-color);
}
.DescriptionContainer p {
    margin: 0;
}
.Topic {
    color: var(--secondery-black-color);
    font-size: 0.8rem;
}
.Controls {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;

}
.CourseImageContainer {
    
    z-index: 10;
    bottom: 0.5rem;
    right: 0.5rem;
    border-radius: 10px;
    /* background-color: rgba(255, 255, 255, 0.25); */

    margin-left: 0.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.CourseImage {
    transition: 0.3s all ease;
    width: 1.75rem;
    height: 1.75rem;
    /* border-radius: 10px; */
    opacity: 0.75;
    border-radius: 5px;
}
.CourseImageContainer:hover .CourseImage {
    opacity: 1;

}
/* .DurationContainer {
    position: absolute;
    z-index: 10;
    bottom: 0.5rem;
    left: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.25);

}
.Duration {
    color: white;
    margin: 0;
    font-size: 0.75rem;
} */
@keyframes OnLoad {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}