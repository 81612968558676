.ContentPage {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 30rem;
    text-align: right;
    min-height: 100vh;
    padding-bottom: 4rem;
    
}


.CourseModule {
    
    width: 29rem;
    position: fixed;
    left: 1rem;
    top: 6rem;
    height: calc(100vh - 7rem);
    box-shadow: var(--primary-black-shadow );
    padding: 0 1rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.CourseModuleLoading {
    align-items: unset;
    padding-top: 2rem;
}
.CourseModuleViewPort {
    overflow: auto;
    height: calc(100% - 2rem);
    width: 100%;
    padding: 1rem;
}

.MainPart {
    width: calc(100vw - 32.5rem);
}

.Loading {
    overflow: hidden;
}
.CourseModuleTitle.LoadingContainer {
    padding: 1rem;
}
.CourseModuleTitle .Loading {
    position: relative;
    height: 4rem;
    margin-top: 2rem;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.0125);
}

.CourseControl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;
}
.NextCourse , .PrevCourse {
background-color: rgba(255, 127, 80, 0);
border-radius: 10px;
display: flex;
align-items: center;
justify-content: space-between;
font-weight: 400;
color: var(--primary-color);
padding: 1rem 0rem;
transition: all 0.3s ease;
}
.NextCourse {
    padding-left: 1.5rem;
}
.PrevCourse {
    padding-right: 1.5rem;
}
.NextCourse:hover  , .PrevCourse:hover {
    background-color: var(--primary-background-color);
    padding: 1rem 1.5rem;

    cursor: pointer;
}
/* .NextCourse:hover svg , .PrevCourse:hover svg {
fill: white;
} */
/* .NextCourse span , .PrevCourse span {
    display: none;
    

} */
.NextCourse svg , .PrevCourse svg {
transition: all 0.3s ease;

    width: 1rem;
    
    margin-left: 0.5rem;
    fill: var(--primary-color);
}


.PrevCourse svg {
    transform: rotateZ(180deg);
    margin-right: 0.5rem;
    margin-left: 0rem;

}
.Spiliter {
    height: 1px;
    width: calc(100%);
    background-color: whitesmoke;
    position: relative;
    
    margin: 1rem 0 ;
}
.ButtonsAndCourseDetailContainer {
    display: flex;
    /* flex-direction: row-reverse; */
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
    /* background-color: rgba(0, 0, 0, 0.0125); */
    /* box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.025); */
    border-radius: 0 0 10px 10px;
    
    margin: 1rem auto;
     
}
.CourseDetail{
    
    text-decoration: none;
    display: flex;
    align-items: center;
    color: var(--secondery-black-color);
}
.CourseDetailImage {
    width: 4rem;
    height: 4rem;
    margin-left: 1rem;
}
.CourseDetailImage.Loading {
    position: relative;

    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.0125);
}
.CourseTitle {
    transition: all 0.3s ease;
    font-weight: 500;
}
.CourseTitle.Loading {
    position: relative;
    overflow: hidden;
    width: 7.5rem ;
    height: 1.5rem;
    background-color: rgba(0, 0, 0, 0.0125);

}
.CourseDetail:hover .CourseTitle {

    color: var(--primary-black-color);
}
.CourseParts , .CourseTime {
    font-size: 0.75rem;
}

.CourseParts.Loading , .CourseTime.Loading {
    position: relative;
    overflow: hidden;
    width: 2.5rem ;
    height: 1rem;
    background-color: rgba(0, 0, 0, 0.0125);
    display: inline-block;
    margin: 0.25rem;
}
.CourseParts.Loading {
    margin-right: 0;
}

/* .DescriptionAndButtons {
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: baseline;
    
} */
.CourseModuleSmallScreen {
    display: none;
}
.Loading{
    overflow: hidden;
}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -33%;
    top: 0;
    height: 100%;
    width: 33%;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;

  }
  .CourseDetailImage.Loading::before , 
  .CourseParts.Loading::before ,
  .CourseTitle.Loading::before,
  .CourseTime.Loading::before,
  .ContentTitle.Loading::before {
      animation-name: load2;
      left: -150px;
      width: 150px;
  }

  @keyframes load {
    from {
        left: -33%;
    }
    to   {
        left: 100%;
    }
  }
  @keyframes load2 {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
  }
@media (max-width : 900px) {
    
    /* .ViewsAndTitleContainer {
        flex-direction: column-reverse;
    } */
    .ViewsAndButtonsContainer {
        justify-content: space-between;
        width: 100%;
    }
    /* .ContentTitle {
        margin: 1rem 0;
    } */
    .MainPart {
        width: auto;
    } 
    .ContentPage {
        display: flex;
        flex-direction: column;
        
    }
    .CourseModuleSmallScreen {
        display: block;
    }
    .CourseModuleBigScreen {
        display: none;
    }
    .CourseModule {
        position: static;
        width: 100%;
        margin-top: 1rem;
        
        border-radius: 10px;
        
    }
  
    .VideoContainer {
        height: calc(100vw / 16 * 9);
        
    }
    
}
@media (min-width: 1600px) {
    
    .VideoContainer , .ViewsAndTitleContainer , .ButtonsAndCourseDetailContainer {
        max-width: none;
        max-height: calc(75rem / 16 * 9);

    }
    .ViewsAndTitleContainer , .ButtonsAndCourseDetailContainer {
        max-height: none;
    }
}
@media (max-width: 600px) {
    .ContentTitle {
        font-size: 1rem;
    }
    .CourseTitle {
        font-size: 0.75rem;
    }
    .CourseParts , .CourseTime {
        font-size: 0.55rem;
    }
}