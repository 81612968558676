.FileDownloadButton {
    background-color: rgba(0, 0, 0, 0.0125);
    position: relative;
    /* border: 1px solid whitesmoke; */
    text-decoration: none;

}
.FileDownloadButton button , .FileDownloadButton button:disabled{
    transition:all 0.3s ease;
    background-color: rgba(0, 0, 0, 0.0125);
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    border-radius: 10px;
    text-decoration: none;
    color: var;
    font-size: 0.75rem;
    text-align: center;
    font-family: inherit;
    border: none;
    padding: 1rem;
    text-decoration: none;

}
.FileDownloadButton button:disabled {
    background-color: rgba(0, 0, 0, 0.05);

}
.FileDownloadButton button svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0.5rem;
    fill: var(--primary-black-color);
}
.FileDownloadButton button span {
    color: var(--primary-black-color);
    text-decoration: none;
}
.FileDownloadButton:hover button {
    background-color: rgba(0, 0, 0, 0.025);
    color: black;
    cursor: pointer;
}
.FileDownloadButton:hover button:disabled {
    border: none;
    background-color: rgba(0, 0, 0, 0.05);

}
.TooltipContainer {
    display: none;
    position: absolute;
    top: -3rem;
    right: 1.5rem;

}
.FileDownloadButton:hover .TooltipContainer{
    display: block;
}
.FileDownloadButton.Loading :hover button:disabled {
    cursor: auto;
    background-color: rgba(0, 0, 0, 0.0125);

}
.FileDownloadButton.Loading  button {
    background-color: rgba(0, 0, 0, 0.0125);
    position: relative;
    overflow: hidden;
} 
.FileDownloadButton.Loading .TooltipContainer {
    display: none;
}
.FileDownloadButton.Loading button svg{
    fill: rgba(0, 0, 0, 0);
}
.FileDownloadButton.Loading button span {
    color: rgba(0, 0, 0, 0);
    
}
.Loading button::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;

  }
  @keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
  }