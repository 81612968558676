.Like {
    margin-right: 0.25rem;
    transition: all 0.3s ease;
    min-width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    /* background-color: rgba(0, 0, 0, 0.025); */
    padding: 0 0.5rem;
    position: relative;
}
.Like svg {
    height: 50%;
    fill: var(--secondery-black-color);
    

}
.Like:hover {
   cursor: pointer;
   background-color: rgba(255, 0, 75, 0.05);
    
}
.Like:hover svg , .Like.IsLiked svg{
   
    fill: rgb(255, 0, 75);
     
 }
 .Like:hover .count , .Like.IsLiked .count {
    color: rgb(255, 0, 75);
 }
 .count {
     margin-left: 0.25rem;
     font-size: 0.75rem;
     color: var(--secondery-black-color);

 }
 .TooltipContainer {
    display: none;
    position: absolute;
    top: -3rem;
}
.Like:hover .TooltipContainer {
    display: block;
}