.Tags {
    border-top: 1px solid whitesmoke;
    border-bottom: 1px solid whitesmoke;
    padding-bottom: 1rem;
}
.TagsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.Title {
    font-weight: 400;
    font-size: 1rem;
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
}
