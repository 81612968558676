.SearchPage {
    min-height: 100vh;
}
.Header {
    width: 100%;
    padding: 3rem;
    display: flex;
    border-bottom: 1px solid whitesmoke;
    align-items: flex-start;
    justify-content: space-between;
    /* position: sticky;
    top: 5rem;
    background-color: white; */
}
.Courses {
    
    scroll-margin-top: 11.75rem;

}
.Title {
    font-size: 1.25rem;
    font-weight: 450;
    color: var(--primary-black-color);
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.SearchResultsCountContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.0125);
    border-radius: 10px;
    margin-top: 0.5rem;
}
.SearchResult {
    padding: 1rem;
}
.SearchResultCountNum span:not(.SearchResultCountTitle) , .SearchValue {
    transition: 0.3s all ease;
    text-align: center;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.0125);
    border-radius: 10px;
    display: inline-block;
    
    font-weight: 500;
    color: var(--secondery-black-color);
    margin-top: 0.5rem;
    min-width: 3rem;
}
.SearchResultCountNum {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem;
    flex-direction: column;
    text-decoration: none;

}
.SearchResultCountNum .SearchResultCountTitle {
    color: var(--secondery-black-color);
    font-weight: 500;
    transition: all 0.3s ease;
    font-size: 1rem;
    
}
a.SearchResultCountNum:hover .SearchResultCountTitle  {
    color: var(--primary-color);
}
a.SearchResultCountNum:hover span:not(.SearchResultCountTitle){
    background-color:var(--primary-background-color);
    color: var(--primary-color);

}
.SearchValue {
    display: block;
    min-width: 12.5rem;
}
.SearchResultTitle {
    text-align: right;
    color: var(--primary-black-color);
    
}
.Videos , .Articles {
    scroll-margin-top: 10rem;

}
.Line {
    width: 100%;
    height: 1px;
    background-color: whitesmoke;
}
.EmptyContainer {
    grid-template-columns: 1fr !important;
}
@media (max-width : 600px) {
    
    .Header {
        flex-direction: column;
        align-items: center;
    }
    .Title {
        margin: 0.5rem;
    }
}
