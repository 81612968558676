.Login {
   
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 1rem; */
    min-height: calc(100vh - 5rem);
    /* background: url('./img/Background.jpg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left;
    background-attachment:scroll ; */
    align-items: center;
    justify-content: center;
    background-color: lightblue;
    transition: background-color 2s ease;
}
.Inputs , .CallToAction {
    width: 80%;
    max-width: 25rem;
}
.InputsContainer {
    background: rgb(255, 255, 255);
    /* border: 1px solid white; */
    /* backdrop-filter: blur(30px); */
    width: 100%;
    /* border-radius: 10px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 4rem;
    /* border-radius: 10px; */
    position: absolute;
    top: 5rem;
    right: 0;
    height: calc(100% - 5rem);
    max-width: 42.5rem;
    box-shadow: var(--primary-black-shadow );
    
}
.SignInWithGoogle {
    transition: all 0.3s ease;
    border: none;
    border-radius: 10px;
    width: 100%;
    padding: 0.75rem 1rem;
    outline: none;
    background-color: white;
    /* box-shadow: var(--primary-black-shadow ); */
    font-family: inherit;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    color: var(--secondery-black-color);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.3s ease;
    border: 1px solid whitesmoke;

}
.SignInWithGoogle:hover {
    cursor: pointer;
    border-color: rgba(255, 255, 255, 0);
    box-shadow: var(--primary-black-shadow);
}
.SignInWithGoogle:hover img {
    background-color: whitesmoke;
    border-radius: 10px;

}
.SignInWithGoogle img {
    height: 3rem;
    width: 3rem;
    border-right: 1px solid whitesmoke;
    padding: 0.5rem;
    transition: inherit;
}

.Or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;
}
.Or div{
    background-color: whitesmoke;
    height: 1px;
    width: calc(50% - 1rem);
}
.Or span {
    color: var(--secondery-black-color);
}
.Input {
    transition: all 0.3s ease;
    padding: 1.25rem;
    font-size: 1rem;
    border-radius: 10px;
    border: none;
    margin: 0.5rem 0;
    /* margin: 1rem; */
    width: 100%;
    background-color: rgba(0, 0, 0, 0.025);
    color: rgb(80, 80, 80);
    /* border: 2.5px solid rgba(255, 127, 80, 0.0); */


}
.Title {
    color: rgb(80, 80, 80);
    font-size: 2.5rem;
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
}
.Inputs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    
    
    /* padding: 1rem; */
}
.Input:focus {
    /* border: 2.5px solid rgb(255, 172, 142); */
    outline: none;
    background-color: white;
    box-shadow: var(--primary-black-shadow );
}
.Label {
    color: rgb(80, 80, 80);

    /* width: 100%; */
    text-align: right;
    font-weight: normal;
    margin: 1rem 0;
    font-size: 1rem;
}

.CallToAction {
    transition: all 0.3s ease;
    padding: 1rem;
    background-color: rgb(255, 127, 80);
    color: white;
    border-radius: 10px;
    font-family: inherit;
    border: 1.5px solid rgba(245, 245, 245, 0.2);
    font-size: 1rem;
    margin: 0.5rem 0;

}
.CallToAction:hover {
    /* background-color: white;
    color: black;
    border: 1.5px solid rgba(100, 100, 100, 0); */
    box-shadow: 0 0 15px 1px rgba(255, 127, 80, 0.5);

    cursor: pointer;
}
.CallToAction:focus {
    outline: none;
}
.ErrorAndLabelContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 1rem; */
}
.ErrorMessage {
    min-width: 1rem;
    min-height: 1rem;
    color: rgb(255, 119, 119);
    /* font-weight: bold; */
    font-size: 0.75rem;
    text-align: left;
}
.Agreements {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.Agreements input {
    margin: 0 ;
    height: 1rem;
    width: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 2px solid rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    outline: none;
    transition-duration: 0.3s;
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    position: relative;
    
}
.Agreements input:hover {
    border-color: var(--primary-color);
}
.Agreements input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);

}
.Agreements input:checked::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: 0.1rem;
    top: -0.07rem;
    font-size: 0.7rem;
    font-weight: bold;
}
.Agreements p {
    color: rgb(80, 80, 80);
    margin: 0;
}
.ChangeMethod {
    margin-top: 0.5rem;
    margin-top: 1rem;
}
.ResetPass {
    margin-top: 1rem;
}
.ChangeMethod a , .ResetPass{
    text-decoration: none;
    color: var(--primary-blue-color);
}
button:disabled {
    background-color: darkgrey;
    color: white;
}
button:disabled:hover{
    background-color: darkgrey;
    color: whitesmoke;
    border: rgba(245, 245, 245, 0) 1.5px solid;
    cursor: not-allowed;
    box-shadow: none;
}
.Description {
    color: white;
    width: calc(100vw - 42.5rem);
    position: absolute;
    left: 0;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 5rem);
    justify-content: center;
}
.Description .SVGImage {
    max-width: 8rem;
    max-height: 8rem;
    position: absolute;
    z-index: 0;
    filter: drop-shadow(0 8px 5px rgba(255, 255, 255, 0.842));
}
.Description h2 {
    font-size: 7.5rem;
    width: 45rem;
    margin: 0;
    z-index: 2;
}
.Description p {
    width: 45rem;
    font-size: 1.25rem;
    z-index: 2;

}
@media (max-width : 600px){
   
    .InputsContainer {
        padding: 0.5rem;
    }
}
@media (max-width : 1450px) {
    .Description h2 {
        font-size: 5rem;
        width: 30rem;
    }
    .Description p {
        width: 30rem;
    }
}
@media (max-width : 1200px) {
    .Description h2 {
        font-size: 3rem;
        width: 20rem;
    }
    .Description p {
        width: 20rem;
    }
}
@media (max-width : 1000px) {
    .Description {
      display: none;
    }
    

}