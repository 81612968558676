.HamMenu {
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 1.5rem;
    height: 1.5rem;
   
    
}
.HamMenu span {
    transition: all 0.3s ease;

    width: 100%;
    height: 2px;
    border-radius: 10px;
    background-color: var(--primary-black-color);
}
.HamMenu:hover span {
    background-color: var(--primary-color);
    
}
.HamMenu.White span , .HamMenu:hover.White span {
    background-color: white ;
}
.HamMenu:hover {
    cursor: pointer;
}
@media (min-width: 900px) {
    .HamMenu {
        display: none;
    }
}