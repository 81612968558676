.HeroSlider {
    padding: 0 1rem;
    height: calc(100vw / 16 * 9) !important;
    border-radius: 10px;
    
    /* min-height: 33vh;    */
    margin: auto;
    margin-top: 6rem;
    margin-bottom: 3rem;
}


.SwiperSlide {
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 100% !important; */
    height: calc(100vw / 16 * 9) !important;
    /* max-height: calc(100vh - 5rem - 2rem); */
    /* min-height: 33vh; */
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    color: whitesmoke;
}
.SwiperSlide:hover {
    cursor: pointer;
    background-color:rgba(255, 255, 255, 0.5) ;
}

.Title {
    font-size: 14rem;
    width: 80%;
    text-shadow: 15px 5px black;
}
.Loading {
    width: 100%;
    height: 100%;
    background-color: rgba(245, 245, 245, 0.5);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -33%;
    top: 0;
    height: 100%;
    width: 33%;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
@keyframes load {
    from {
        left: -33%;
    }
    to   {
        left: 100%;
    }
}

@media (min-width : 1300px ) {
    .HeroSlider {
        max-height: none;
    height: calc(calc(100vw - 15rem) / 16 * 9) !important;
    width: calc(100vw - 15rem);

    }
    .SwiperSlide {
        max-height: none;
        height: calc(calc(100vw - 15rem) / 16 * 9) !important;
        width: 100%;
    }
}
