.Button {
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-size: 1rem;
  margin:0;
  border: 1px solid rgba(245, 245, 245, 0.2);
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.primary {
  background-color: rgb(255, 127, 80);
  color: white;
}
.primary:hover {
  box-shadow: 0 0 15px 1px rgba(255, 127, 80, 0.5);
  cursor: pointer;
}
.primary:focus {
  outline: none;
}

.soft  , .plain{
  border: 1px solid rgba(0, 0, 0, 0);
  font-size: 1rem;
  color: black;
  text-decoration: none;
  transition: all 0.2s ease;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.025);

}
.soft:hover , .plain:hover {
  border: 1px solid rgba(0, 0, 0, 0);
  color: var(--primary-color);
  background-color: var(--primary-background-color);
  border-radius: 10px;
  cursor: pointer;
}

.plain {
  background-color: rgba(0, 0, 0, 0);
  color: black;
}


