.EmojiPicker {
    animation: EmojiPopUp 0.3s ease 0s 1;
    position: absolute;
    z-index: 5556;
    top: 0.75rem;
    right: -1rem;
    transform-origin: top right;
    position: relative;
}
.EmojiPicker::before {
    width: 1rem;
    height: 1rem;
    content: "";
    background-color: white;
    position: absolute;
    top: -0.25rem;
    right: 1rem;
    transform: rotateZ(45deg);

}
.IconContainer {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.IconContainer svg {
    transition: 0.3s ease all;
    width: 1rem;
    height: 1rem;
    fill: var(--secondery-black-color);
}
.IconContainer:hover svg{
    cursor: pointer;
    fill: black;
}
@keyframes EmojiPopUp {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
@media (max-width : 500px){
    .EmojiPicker {
        right: -7rem;
    }
    .EmojiPicker::before {
        display: none;
    }
}