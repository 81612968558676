
body {
  text-align: center;
  min-height: calc(100vh - 5rem);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
