.Image {
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 10px;
}
.Slide {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Name {
    margin: 0.5rem 0;
    margin-bottom: 0.25rem;
    color: var(--primary-black-color);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
}
.Parts , .Time {
    margin: 0;
    color: var(--secondery-black-color);
    font-size: 0.65rem;
}
.Time {
    margin-right: 0.5rem;
}