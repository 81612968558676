.LoginButton {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
}
.Line {
    display: none;
    margin: 0 0.5rem;
    width: 1px;
    height: 1rem;
    border-radius: 10px;
    background-color: var(--secondery-black-color);
    /* box-shadow: 0 0 10px 2.5px rgba(0, 0, 0, 0.05); */
}
.Link {
    border: 1px solid rgba(0, 0, 0, 0);
    font-size: 1rem;
    color: black;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: all 0.2s ease;
    margin: 0.25rem;
    border-radius: 10px;
}
.Link:hover {
    border: 1px solid rgba(0, 0, 0, 0);
    color: var(--primary-color);
    background-color: var(--primary-background-color);
    border-radius: 10px;
    cursor: pointer;
}

.SignUp {
    background-color: rgba(0, 0, 0, 0.025);
    color: black;
}
.White .SignUp {
    background-color: rgba(255, 255, 255, 0.25);
}
.White .Link {
    color: white;
}
@media (max-width: 900px) {
    .LoginButton {
        flex-direction: column;
        
    }
    .Link {
        width: calc(100% - 2rem);
        text-align: right;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0);
        color: var(--primary-color);
    }
}