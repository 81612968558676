.Search {
  transition: all 0.3s ease;
  margin: 0 0.5rem;
  background-color: rgba(245, 245, 245, 0.5);
  padding: 1rem 1.5rem;
  padding-left: 2.5rem;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  color: black;
  width: 100%;
  min-width: 15rem;
}
.Search:focus {
  outline: none;
  background-color: white;
  box-shadow: var(--primary-black-shadow);

  /* border: 5px solid rgba(255, 127, 80, 0.25); */
}
.Search::-webkit-search-cancel-button {
  display: none;
}
.Icon {
  transition: fill 0.3s ease;

  position: absolute;
  left: 0.75rem;
  top: 1.25rem;
  width: 1rem;
  fill: var(--primary-black-color);
  z-index: 5;
}
.Icon:hover {
  cursor: pointer;
  fill: var(--primary-color);
}
.SearchContainer {
  position: relative;
}
.Close {
  transition: opacity 0.3s ease;
  opacity: 0;
  z-index: 10;
  height:0rem;
  width: 0rem;
  fill: var(--secondery-black-color);
}
.Close:hover svg {
  fill: var(--primary-color);
  cursor: pointer;
}
@media (max-width: 900px) {
  .Search {
    width: 0;
    min-width: unset;
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: padding 0.3s ease;
    transition-property: padding, box-shadow;
  }
  .Icon {
    position: relative;
    right: 1rem;
    top: 0.25rem;
    width: 1.25rem;
    height: 1.25rem;
    transition: all 0.3s ease;
    transition-property: width, height;
  }
  .SearchActive input {
    display: block;
    width: calc(100vw) !important;
    height: 5rem;
    background-color: white;
    z-index: 100;
    position: absolute;
    margin: 0;
    outline: none;
    background-color: white;
    box-shadow: var(--primary-black-shadow);
    left: 0;
    top: 0;
    border-radius: 0;
    opacity: 1;
  }
  .SearchContainer.SearchActive {
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
  }
  .SearchContainer.SearchActive .Search {
    padding-right: 4rem;
  }
  .SearchContainer.SearchActive .Icon {
    position: absolute;
    z-index: 101;
    top: 1.85rem;
    right: 1.25rem;
    width: 1rem;
  }
  .SearchContainer.SearchActive .Close {
    display: flex;
    position: absolute;
    z-index: 105;
    top: 1.75rem;
    left: 1rem;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    height:1.5rem;
    width: 1.5rem;
    opacity: 1;
  }
}
