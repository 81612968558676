.AppsList {
    position: absolute;
    top: 4.6rem;
    left: 0;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: var(--primary-black-shadow );
    animation: OnLoad 0.3s ease  1;
    overflow: hidden;
    padding: 0.25rem;

}
.EmptyBox {
    margin-top: 1rem;
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.EmptyBox h1 {
    margin-top: 3rem;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.0125);
    /* border: 2px solid var(--primary-color); */
    color: var(--secondery-black-color);
    font-size: 1.5rem;
}
.EmptyBoxImage {
    width: 8rem;
    height: 8rem;
    /* background-color: rgba(0, 0, 0, 0.0125); */
    border-radius: 10px;
   
}
.AppsListViewPort {
    /* display: flex;
    flex-direction: column; */
    align-items: center;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.Line {
    width: 100%;
    height: 1px ;
    background-color: whitesmoke;
    margin: 0.25rem 0;
    color: rgba(0, 0, 0, 0);
    
}
@keyframes OnLoad {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;

    }
}