.Text , .Video , .Quiz , .ComingSoon {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
}
.Checked svg , .Text svg , .Video svg , .ComingSoon svg  {
    width: 60%;
    height: 60%;
}


.Text {
    background-color: rgba(225, 0, 255, 0.05);
}
.Text svg {
    fill: rgb(225, 0, 255);
}
.Video {
    background-color: rgba(0, 119, 255, 0.05);
}
.Video svg {
    fill: rgb(0, 120, 255);
}
.Quiz {
    background-color: rgb(0, 192, 80, 0.05);
}

.Quiz span {
    font-size: 1.25rem;
    font-weight: bold;
    color:rgb(0, 192, 80) ;
    position: relative;
    top: 0.0625rem;
}
.ComingSoon {
    background-color: rgba(0, 0, 0, 0.05);

}
.ComingSoon svg {
    fill: rgba(0, 0, 0, 0.75);
}