.video-js {
    width: 100%  !important;
    height: 100%  !important;
    box-shadow: var(--primary-black-shadow);
}
.video-js , .video-js video {
    border-radius: 5px !important;
    
}
video[poster]{
    object-fit: none !important;
  }
  .skip-forward:hover , .skip-back:hover {
      cursor: pointer !important;
  }
  /* .skip-forward:hover .vjs-icon-placeholder::before {
    content: "+15" !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
}
.skip-back:hover .vjs-icon-placeholder::before {
    content: "-15" !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    
} */
  .skip-forward .vjs-icon-placeholder::before {
      content: "\21B7" !important;
      
  }
  .skip-back .vjs-icon-placeholder::before {
      content: "\21B6" !important;
  }
  .vjs-poster {
    background-size: auto 100% !important;
    background-position: center !important;
    border-radius: 5px !important;
  }
/* .vjs-big-play-button {
    font-size: 5em !important;
    line-height: 1.5em !important;
} */
.vjs-control-bar {
    background-color: rgb(0, 0, 0)  !important;
    backdrop-filter: blur(5px) !important;
    /* border: 1px solid rgba(255, 255, 255, 0.25) !important; */
    
    
    /* width: calc(100% - 0.5rem)  !important; */
    height: 4em  !important;
    padding: 0.25rem !important;
    align-items: center  !important;
}
/* .vjs-slider , .vjs-progress-control , .vjs-load-progress{
    border-radius: 5px  !important;

} */
.vjs-slider {
    background-color: rgba(190, 190, 190, 0.75)  !important;
}
.vjs-load-progress {
    background-color: rgba(255, 255, 255, 0.5)  !important;
}
.vjs-control {
    vertical-align: middle !important;
    align-items: center !important;
}








.video-js .vjs-menu-button-inline.vjs-slider-active,.video-js .vjs-menu-button-inline:focus,.video-js .vjs-menu-button-inline:hover,.video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 10em
}

.video-js .vjs-controls-disabled .vjs-big-play-button {
    display: none!important
}

.video-js .vjs-control {
    width: 3em
}

.video-js .vjs-menu-button-inline:before {
    width: 1.5em
}

.vjs-menu-button-inline .vjs-menu {
    left: 3em
}

.vjs-paused.vjs-has-started.video-js .vjs-big-play-button,.video-js.vjs-ended .vjs-big-play-button,.video-js.vjs-paused .vjs-big-play-button {
    display: block !important;
}

.video-js .vjs-load-progress div,.vjs-seeking .vjs-big-play-button,.vjs-waiting .vjs-big-play-button {
    display: none  !important;
}

.video-js .vjs-mouse-display:after,.video-js .vjs-play-progress:after {
    padding: 0 .4em .3em !important;
}

.video-js.vjs-ended .vjs-loading-spinner {
    display: none !important;
}

.video-js.vjs-ended .vjs-big-play-button {
    display: block  !important;
}

.video-js *,.video-js:after,.video-js:before {
    box-sizing: inherit !important;
    font-size: inherit !important;
    color: inherit !important;
    line-height: inherit !important;
}

.video-js.vjs-fullscreen,.video-js.vjs-fullscreen .vjs-tech {
    width: 100% !important;
    height: 100% !important;
}

.video-js {
    font-size: 14px !important;
    overflow: hidden !important;
}

.video-js .vjs-control {
    color: inherit !important;
}

.video-js .vjs-menu-button-inline:hover,.video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 8.35em
}

.video-js .vjs-volume-menu-button.vjs-volume-menu-button-horizontal:hover .vjs-menu .vjs-menu-content {
    height: auto !important;
    width: 6.35em;
}

.video-js .vjs-control:focus:before,.video-js .vjs-control:hover:before {
    text-shadow: 0 0 1em #fff,0 0 1em #fff,0 0 1em #fff
}

.video-js .vjs-spacer,.video-js .vjs-time-control {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-box-flex: 1 1 auto !important;
    -moz-box-flex: 1 1 auto !important;
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto
}

.video-js .vjs-time-control {
    -webkit-box-flex: 0 1 auto !important;
    -moz-box-flex: 0 1 auto !important;
    -webkit-flex: 0 1 auto !important;
    -ms-flex: 0 1 auto !important;
    flex: 0 1 auto !important;
    width: auto
}

.video-js .vjs-time-control.vjs-time-divider {
    width: 14px
}

.video-js .vjs-time-control.vjs-time-divider div {
    width: 100% !important;
    text-align: center
}

.video-js .vjs-time-control.vjs-current-time {
    margin-left: 1em
}

.video-js .vjs-time-control .vjs-current-time-display,.video-js .vjs-time-control .vjs-duration-display {
    width: 100%
}

.video-js .vjs-time-control .vjs-current-time-display {
    text-align: right
}

.video-js .vjs-time-control .vjs-duration-display {
    text-align: left
}

.video-js .vjs-play-progress:before,.video-js .vjs-progress-control .vjs-play-progress:before,.video-js .vjs-remaining-time,.video-js .vjs-volume-level:after,.video-js .vjs-volume-level:before,.video-js.vjs-live .vjs-time-control.vjs-current-time,.video-js.vjs-live .vjs-time-control.vjs-duration,.video-js.vjs-live .vjs-time-control.vjs-time-divider,.video-js.vjs-no-flex .vjs-time-control.vjs-remaining-time {
    display: none
}

.video-js.vjs-no-flex .vjs-time-control {
    display: table-cell !important;
    width: 4em
}

.video-js .vjs-progress-control {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    height: .5em !important;
    top: -.5em
}

.video-js .vjs-progress-control .vjs-load-progress,.video-js .vjs-progress-control .vjs-play-progress,.video-js .vjs-progress-control .vjs-progress-holder {
    height: 100%  !important;
}

.video-js .vjs-progress-control .vjs-progress-holder {
    margin: 0 !important;
    
}

.video-js .vjs-progress-control:hover {
    height: 0.9em  !important;
    top: -0.9em  !important;
}

.video-js .vjs-control-bar {
    -webkit-transition: -webkit-transform .1s ease 0s !important;
    -moz-transition: -moz-transform .1s ease 0s !important;
    -ms-transition: -ms-transform .1s ease 0s !important;
    -o-transition: -o-transform .1s ease 0s !important;
    transition: transform .1s ease 0s  !important;
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar,.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar,.video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar {
    visibility: visible !important;
    opacity: 1 !important;
    -webkit-backface-visibility: hidden !important;
    -webkit-transform: translateY(5em) !important;
    -moz-transform: translateY(5em) !important;
    -ms-transform: translateY(5em) !important;
    -o-transform: translateY(5em) !important;
    transform: translateY(5em) !important;
    -webkit-transition: -webkit-transform 1s ease 0s !important;
    -moz-transition: -moz-transform 1s ease 0s !important;
    -ms-transition: -ms-transform 1s ease 0s !important;
    -o-transition: -o-transform 1s ease 0s !important;
    transition: transform 1s ease 0s
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control,.video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control {
    height: .25em  !important;
    top: -.25em  !important;
    pointer-events: none !important;
    -webkit-transition: height 1s,top 1s !important;
    -moz-transition: height 1s,top 1s !important;
    -ms-transition: height 1s,top 1s !important;
    -o-transition: height 1s,top 1s !important;
    transition: height 1s,top 1s !important;
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active.vjs-fullscreen .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active.vjs-fullscreen .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,.video-js.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
    opacity: 0 !important;
    -webkit-transition: opacity 1s ease 1s !important;
    -moz-transition: opacity 1s ease 1s !important;
    -ms-transition: opacity 1s ease 1s !important;
    -o-transition: opacity 1s ease 1s !important;
    transition: opacity 1s ease 1s
}

.video-js.vjs-live .vjs-live-control {
    margin-left: 1em
}

.video-js .vjs-big-play-button {
    top: 50%  !important;
    left: 50%  !important;
    margin-left: -1em  !important;
    margin-top: -1em  !important;
    width: 2em  !important;
    height: 2em  !important;
    line-height: 2em  !important;
    border: none  !important;
    border-radius: 50%  !important;
    font-size: 3.5em  !important; 
    background-color: rgba(0,0,0,.45)  !important;
    color: #fff  !important;
    -webkit-transition: border-color .4s,outline .4s,background-color .4s !important;
    -moz-transition: border-color .4s,outline .4s,background-color .4s !important;
    -ms-transition: border-color .4s,outline .4s,background-color .4s !important;
    -o-transition: border-color .4s,outline .4s,background-color .4s !important;
    transition: border-color .4s,outline .4s,background-color .4s  !important;
}

.video-js .vjs-menu-button-popup .vjs-menu {
    left: -3em
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-content  {
    background-color: white !important;
    width: 12em !important;
    left: -1.5em !important;
    border-radius: 2.5px !important;
    overflow: auto !important;
    box-shadow: var(--primary-black-shadow);
    padding: 0.25rem;
}
.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-content .vjs-menu-title , .video-js .vjs-remaining-time {
    display: none !important;
}
.video-js .vjs-time-control {
    padding: 0 !important;
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item,.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-title {
    background-color: white !important;
    color: black !important;
    margin: .25em .0125em !important;
    padding: 0.5em !important;
    font-weight: 300 !important;
    border-radius: 5px !important;
    font-size: 0.9rem !important;
}
.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item:hover,.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-title:hover {
    background-color: rgb(243.2, 243.2, 243.2) !important;
    color: black !important;
    background-color: rgb(255, 250, 248) !important;
    
    color: var(--primary-color) !important;
}
.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
    background-color: rgba(255, 127, 80, 0.75) !important;
    /* border: 1px solid coral; */
    color: white !important;
    font-weight: bold !important;
}

/* .video-js .vjs-big-play-button {
    background-color: rgba(0,0,0,0.05) !important;
    font-size: 3em !important;
    border-radius: 10px !important;
    height: 1.4em  !important;
    line-height: 1.4em  !important;
    margin-top: -0.7em !important
} */

.video-js:hover .vjs-big-play-button,.video-js .vjs-big-play-button:focus,.video-js .vjs-big-play-button:active {
    background-color: rgba(255, 127, 80, 0.75)  !important;
}

.video-js .vjs-loading-spinner {
    border-color: var(--primary-color) !important;
}

.video-js .vjs-control-bar2 {
    background-color: #000000 !important;
}

.video-js .vjs-control-bar {
    background-color: rgb(255, 255, 255)  !important;
    color: #000000be  !important;
    font-size: 12px  !important;
}
@media (max-width : 520px) {
    .video-js .vjs-control-bar {
        font-size: 9px  !important;
    }
}
.video-js .vjs-play-progress,.video-js  .vjs-volume-level {
    background-color: var(--primary-color) !important;
}

.video-js .vjs-load-progress {
    background: rgba(255, 255, 255, 0.5) !important;
}
.vjs-playback-rate .vjs-playback-rate-value {
    font-size: 1em !important;
    top: 1em !important;
}
.vjs-mouse-display .vjs-time-tooltip {
    color: white !important;
}
.ima-progress-div {
    background-color: rgb(255, 230, 0)  !important;
}

.ima-countdown-div {
    font-family: IranSans !important;
    margin-right: 0.25rem !important;
    text-shadow: 0 0 4px rgb(0 0 0 / 0%)  !important;
    font-weight: normal !important;
    opacity: 0.9 !important;
    font-size: 0.75rem !important;
    bottom: 1.5rem !important;
    left: calc(100% - 13rem) !important;
    height: auto  !important;
    padding: 0.75rem !important;
    background-color: rgb(255, 255, 255) !important;
    box-shadow: var(--primary-black-shadow) !important;
    max-width: 12.5rem !important;
    text-align: center !important;
    border-radius: 10px !important;
    position: relative !important;
    color: var(--secondery-black-color)  !important;
}
.ima-ad-container {
    direction: ltr  !important;
    
}
.ima-controls-div {
    background: none  !important;
    text-shadow: 0 0 2px rgb(0 0 0 / 50%)  !important;
    color: white  !important;
    overflow: unset  !important;
}
.ima-controls-div-showing {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 75%)  !important;

}
.ima-play-pause-div, .ima-mute-div, .ima-slider-div, .ima-fullscreen-div {
    color: white  !important;
    opacity: 0.9 !important;
    font-size: 1.75rem  !important;
    top: 0.25em  !important;
}
.ima-slider-div  {
    top: 1em  !important;
} 
.ima-slider-level-div , .ima-slider-div {
    height: 0.4em  !important;
    width: 3em  !important;
    border-radius: 2px !important;
    background-color: white  !important;
}
.ima-slider-div {
    background-color: rgba(255, 255, 255, 0.25)  !important;
}