
.CourseModuleItem {
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border-radius: 10px;
    text-decoration: none;
}
.CourseModuleItem.IsActive {
    pointer-events: none;
}
.CourseModuleItem.IsActive.Video ,
.CourseModuleItem.Video:hover ,
.CourseModuleItem.Video:hover .Button ,
.CourseModuleItem.Video:hover .Checked  {
    background-color: rgba(0, 119, 255, 0.05);
    color: rgb(0, 119, 255);
    
}
.CourseModuleItem.IsActive.Video .Title ,
.CourseModuleItem.Video:hover .Title {
    color: rgb(0, 119, 255);
}
.CourseModuleItem.Video:hover .Checked svg {
    fill:rgb(0, 119, 255) ;

}
.CourseModuleItem.IsActive.Text , 
.CourseModuleItem.Text:hover , 
.CourseModuleItem.Text:hover .Button,
.CourseModuleItem.Text:hover .Checked{
    background-color: rgba(225, 0, 255, 0.05);
    color: rgb(225, 0, 255);
}
.CourseModuleItem.IsActive.Text .Title  , .CourseModuleItem.Text:hover .Title{
    color: rgb(225, 0, 255);
}
.CourseModuleItem.Text:hover .Checked svg {
    fill:rgb(225, 0, 255) ;
}
.CourseModuleItem.IsActive.Quiz , 
.CourseModuleItem.Quiz:hover , 
.CourseModuleItem.Quiz:hover .Button,
.CourseModuleItem.Quiz:hover .Checked{
    background-color: rgba(0, 192, 80, 0.05);
    color: rgb(0, 192, 80);
}
.CourseModuleItem.IsActive.Quiz .Title  , .CourseModuleItem.Quiz:hover .Title{
    color: rgb(0, 192, 80);
}
.CourseModuleItem.Quiz:hover .Checked svg {
    fill:rgb(0, 192, 80) ;
}
.CourseModuleItem.ComingSoon {
    pointer-events: none;
}
.CourseModuleItem:hover {
    /* background-color: rgba(0, 0, 0, 0.0125); */
    cursor: pointer;
    /* background-color: white; */
    /* box-shadow: var(--primary-black-shadow ); */
}
.Subject {
    display: flex;
    align-items: center;
}

.Title {
    margin-right: 1rem;
    font-size: 1rem;
    font-weight: normal;
    color: var(--secondery-black-color);
    word-wrap: break-word;
}
.Index {
    width: 2rem;
    height: 2rem;
    background-color: rgba(0, 0, 0, 0.025);
    /* background-color: rgba(255, 127, 80, 0.05); */
    /* color: var(--primary-color); */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    display: none;

    /* color: var(--secondery-black-color); */
    /* border: 2.5px solid rgba(0, 0, 0, 0.05); */
}

.Index span {
    /* color: var(--primary-color); */
}
.Line {
    width: 1px;
    height: 1.5rem;
    background-color: rgba(0, 0, 0, 0.05);
    margin: 0 0.5rem;
    display: none;

}

.Checked svg  {
    width: 1rem;
    height: 1rem;
    fill: rgb(0, 220, 90);

}
.Checked , .Button , .Play , .Duration  {
    transition: all 0.3s ease;
    border-radius: 10px;
    /* padding: 0.5rem 1rem; */
    min-width: 4rem;
    min-height: 2.5rem;
    border: none;
    font: inherit;
    background-color: rgba(0, 0, 0, 0.0125);
    font-size: 0.9rem;
    color: var(--secondery-black-color);
}

.Duration {
    padding: 0.5rem;
}
.Checked {
    width: 2rem;
    height: 2rem;
    min-width: unset;
    min-height: unset;
}
.Checked , .Play {
    background-color: rgba(0, 220, 90, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
}
.Video .Play , .Quiz .Play , .Text .Play{
background-color: rgba(0, 89, 255, 0);
}

.Play svg  {
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--primary-color);

}
.Button:hover {
    cursor: pointer;
    background-color: var(--primary-background-color);
    color: var(--primary-color);
}