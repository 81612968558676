.TopContainer {
    display: flex;
    /* align-items: center; */
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-shadow: var(--primary-black-shadow);
    padding : 1rem;
    margin: 0.5rem auto;
    margin-top: 0;
    border-radius: 10px;
}
.Cover {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}
.Cover div {
    font-size: 10rem;
    font-weight: bold;
    color: rgba(0, 190, 101);
    filter: drop-shadow(0px 0px 3px rgba(0, 190, 101)) blur(2px);
    text-shadow:
    3px  3px 0 rgb(255, 255, 255),
   -1px -1px 0 rgb(255, 255, 255),  
    1px -1px 0 rgb(255, 255, 255),
   -1px  1px 0 rgb(255, 255, 255),
    1px  1px 0 rgb(255, 255, 255);
    opacity: 0.5;
    
}
.Cover .FirstQ {
    transform: translateX(-2rem)  scale(1.25);
    animation-delay: 0.5s;
    /* display: none; */
}
.Cover .SecondQ {
    animation-delay: 1s;
    font-size: 15rem;
    transform: translateX(0rem)  scale(1.5);
    filter: drop-shadow(0px 0px 3px rgba(0, 190, 101)) ;

    z-index: 2;
    opacity: 1;

}
.Cover .ThirdQ {
    transform: translateX(2rem)  scale(1.25);
    animation-delay: 1.5s;
    /* display: none; */

}

.DetilsAndButtons {
    margin-left: 1rem;
    padding: 1rem;
    /* border-radius: 10px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}
.Title {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 1rem;
    color: var(--primary-black-color);
    min-height: 2.5rem;
}
.Title.Loading {
    max-width: 15rem;
}
.ButtomContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}
.ButtomPart {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ButtonsContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    
}
.DescriptionTitle {
    margin: 0;
    color: var(--primary-black-color);
    font-size: 1rem;
    padding-right: 0.5rem;
}
.Description {
    max-height: 12.5rem;
    overflow: auto;
    margin: 1rem 0 ;
}
.Description p {
    margin: 0;
}
.FinishButton {
    background-color: coral;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    width: 12.5rem;
    font-family: IranSans;
    font-weight: bold;
    font-size: 1rem;
    transition: all 0.3s ease;
    
}
.FinishButton:hover {
    box-shadow: 0 0 15px 1px rgb(255 127 80 / 50%);
    cursor: pointer;
}
.FinishButton:disabled:hover {
    box-shadow: unset;
    border: none;
}
.Score {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.025);
    padding: 1rem 2rem;
    border-radius: 10px;
    margin: 0;
}
.Score h3 {
    margin: 0;
}
.Score span {
    margin-right: 0.5rem;
    font-weight: bold;
}
.LowScore {
    color: rgba(255, 80, 80, 1);
    background-color: rgba(255, 80, 80, 0.05);
}
.MediumScore {
    color: orange;
    background-color: rgba(255, 166, 0, 0.05);

}
.GoodScore {
    color: rgb(0, 150, 255);
    background-color: rgba(0, 149, 255, 0.05);

}
.GreatScore {
    color: rgba(0, 190, 101);
    background-color: rgba(0, 190, 101, 0.05);

}
.Loading{
    overflow: hidden;
    position: relative;
   background-color: rgba(0, 0, 0, 0.0125);

}
.Loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -33%;
    top: 0;
    height: 100%;
    width: 33%;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;

  }
  @media (max-width: 600px) {
    .ButtomContainer {
        flex-direction: column;
    }
    .FinishButton {
        margin-top: 1rem;
        width: 100%;

    }
    .Score {
        width: 100%;
    }
}
@media (max-width : 1200px) {
 
    .TopContainer {
        flex-direction: column-reverse;
    }
   
    
}
@keyframes load {
    from {
        left: -33%;
    }
    to   {
        left: 100%;
    }
  }
