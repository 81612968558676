.Title {
    margin: 0;
    text-align: right;
    /* padding: 1rem; */
    /* background-color: rgba(0, 0, 0, 0.025; */
    color: white;
    
    
}

.Subject {
    border-bottom: 1px solid whitesmoke;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    background-color: lightgreen;
    width: 100%;
    transition: all 0.3s ease;
}
.ImageContaniner , .ImageLoading{
    position: relative;
    overflow: hidden;
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 1rem; */
    /* background-color: rgba(0, 0, 0, 0.025); */
    border-radius: 10px;
    padding: 1rem;
}
.ImageContaniner {
    background-color: rgba(255, 255, 255, 1);

}
.ImageContaniner img {
    width: 100%;
    /* border-radius: 10px; */
}
.NameLoading {
    width: 10rem;
    height: 4.5rem;
    overflow: hidden;
    position: relative;
    border-radius: 10px;

}
.NameLoading::before  , .ImageLoading::before{
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
} 